var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"navigation"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('LogoIcon')],1)],1),_c('nav',[_c('router-link',{staticClass:"cl-link-btn",attrs:{"to":"/dashboard","title":"Dashboard"}},[_vm._v("Dashboard ")]),(!_vm.isActor)?[_c('router-link',{staticClass:"cl-link-btn",attrs:{"to":"/casting-calls","title":"Casting calls"}},[_vm._v("Casting calls ")])]:_vm._e(),(_vm.isActor)?[_c('router-link',{staticClass:"cl-link-btn",attrs:{"to":"/resume","title":"Resume"}},[_vm._v("Resume")]),_c('router-link',{staticClass:"cl-link-btn",attrs:{"to":"/connections","title":"Resume"}},[_vm._v("Connections")])]:_vm._e()],2)]),_c('v-menu',{staticClass:"header_dropdown",attrs:{"close-on-content-click":false,"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon user-profile"},'div',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/profile.png"),"alt":"Profile"}})])]}}])},[_c('div',{staticClass:"user__dropdown"},[_c('div',{staticClass:"user__dropdown-header"},[_c('span',{staticClass:"user__dropdown-header-image",style:({
            backgroundImage: _vm.user.mainPhoto
              ? 'url(' + _vm.mediaBase + '/' + _vm.user.mainPhoto.uri + ')'
              : 'url(https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png)',
          })}),_c('div',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])]),_c('div',{staticClass:"user__dropdown-actions"},[(!_vm.isActor)?[_c('div',{staticClass:"user__dropdown-actions-item"},[_c('router-link',{attrs:{"to":"/director-account"}},[_vm._v("Profile")])],1)]:_vm._e(),_c('div',{staticClass:"user__dropdown-actions-item logout",on:{"click":_vm.logout}},[_c('span',[_vm._v("Logout")])])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }