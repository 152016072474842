











import { Component, Prop, Vue } from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';
import BookmarkIcon from '@/assets/icons/bookmark.svg';

@Component({ name: 'cl-reminder', components: { CloseIcon, BookmarkIcon } })
export default class ClReminder extends Vue {
  @Prop(String) description!: string;

  public isVisible = true;

  public onClose() {
    this.isVisible = false;
  }
}
