import { ActionTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingSearchState } from '@/_core/store/casting-search/state';
import {
  CastingsSearchActionsTypes,
  CastingsSearchMutationTypes,
} from '@/_core/store/casting-search/types';
import backendless from 'backendless';
import { ICreateSearch } from '@/modules/director/create-search/models/create-search.model';
import router from '@/app-routes';
import { ICastingSearch } from '@/_shared/models/casting-search.model';
import { IActor } from '@/_shared/models/actor.model';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import { handleApiError } from '@/_shared/utils/error-handler.util';

export const actions: ActionTree<ICastingSearchState, IRootState> = {
  async [CastingsSearchActionsTypes.CREATE](
    { commit },
    { roleId, form }: { roleId: string; form: ICreateSearch }
  ) {
    // commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, true);
    //
    // try {
    //   const [roleDetails]: ICastingRole[] = await backendless.Data.of('roles').find({
    //     where: `objectId = '${roleId}'`,
    //   });
    //   const savedSearch: ICastingSearch = await backendless.Data.of('search_history').save<
    //     ICastingSearch
    //   >({
    //     search: JSON.stringify(form),
    //   });
    //   await backendless.Data.of('search_history').setRelation(savedSearch, 'role', [roleId]);
    //   await backendless.Data.of('roles').addRelation(roleDetails, 'search_history', [savedSearch]);
    //
    //   router.push(`/casting-calls/search/${roleId}/${savedSearch.objectId}`);
    //   commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, false);
    // } catch (e) {
    //   commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, false);
    //   handleApiError(e);
    // }

    commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, true);

    try {
      const [roleDetails]: ICastingRole[] = await backendless.Data.of('roles').find({
        where: `objectId = '${roleId}'`,
      });
      const savedSearch: ICastingSearch = await backendless.Data.of('search_history').save<
        ICastingSearch
      >({
        search: JSON.stringify(form),
      });
      await backendless.Data.of('search_history').setRelation(savedSearch, 'role', [roleId]);
      await backendless.Data.of('roles').addRelation(roleDetails, 'search_history', [savedSearch]);

      router.push(`/casting-calls/search/${roleId}/${savedSearch.objectId}`);
      commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, false);
    } catch (e) {
      commit(CastingsSearchMutationTypes.SET_CREATE_LOADING, false);
      handleApiError(e);
    }
  },
  // tslint:disable-next-line:typedef
  async [CastingsSearchActionsTypes.FETCH](
    { commit, dispatch },
    payload: { searchId: string; roleId: string; skip?: boolean }
  ) {
    commit(CastingsSearchMutationTypes.SET_LOADING, true);

    try {
      const [search]: ICastingSearch[] = await backendless.Data.of('search_history').find({
        where: `objectId = '${payload.searchId}'`,
      });

      dispatch(CastingsSearchActionsTypes.FIND_ACTORS, {
        search: search.search,
        roleId: payload.roleId,
        skip: payload.skip,
      });

      commit(CastingsSearchMutationTypes.SET_LOADING, false);
      commit(CastingsSearchMutationTypes.SET_SEARCH, search);
    } catch (e) {
      commit(CastingsSearchMutationTypes.SET_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsSearchActionsTypes.FIND_ACTORS](
    { commit, dispatch },
    payload: { search: ICreateSearch; roleId: string; skip?: boolean }
  ) {
    if (!payload.skip) {
      dispatch(CastingsSearchActionsTypes.CREATE, { roleId: payload.roleId, form: payload.search });
    }

    commit(CastingsSearchMutationTypes.SET_FIND_LOADING, true);

    try {
      const searchTags = payload.search.tags.map((tag) => tag.objectId);
      const actors: IActor[] = await backendless.Data.of('actors').find<IActor>({
        where: `city LIKE '%${payload.search.location}%'`,
        relationsDepth: 3,
      });

      const matchedActors = actors.filter((actor) => {
        const tags = actor.skills.map((skill) => skill.objectId);
        return !searchTags.length
          ? true
          : !searchTags.some((searchTag) => !tags.includes(searchTag));
      });

      commit(CastingsSearchMutationTypes.SET_ACTORS, matchedActors);
      commit(CastingsSearchMutationTypes.SET_FIND_LOADING, false);
    } catch (e) {
      commit(CastingsSearchMutationTypes.SET_FIND_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsSearchActionsTypes.CREATE_MESSAGES](
    { commit, rootState },
    payload: { actors: string[]; roleId: string }
  ) {
    commit(CastingsSearchMutationTypes.SET_CONVERSATIONS_LOADING, true);

    try {
      await backendless.CustomServices.invoke('MessagesService', 'createConversations', {
        actors: payload.actors,
        roleId: payload.roleId,
        directorId: rootState.auth.user.objectId,
      });
    } catch (e) {
      handleApiError(e);
      commit(CastingsSearchMutationTypes.SET_CONVERSATIONS_LOADING, false);
    }

    commit(CastingsSearchMutationTypes.SET_CONVERSATIONS_LOADING, false);
    router.push(`/casting-calls/discussions/${payload.roleId}/messages`).catch(() => {});
  },
};
