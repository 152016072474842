










































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ShareIcon from '@/assets/icons/share.svg';
import MinusIcon from '@/assets/icons/minus-circle.svg';
import { IActor } from '@/_shared/models/actor.model';
import { APP_CONFIG } from '@/_core/constants/app.config';
import ShareShortlist from '@/modules/director/casting-calls/components/share-shortlist/share-shortlist.vue';

@Component({ name: 'casting-shortlist', components: { ShareIcon, MinusIcon, ShareShortlist } })
export default class CastingShortlist extends Vue {
  @Prop() selectedActors!: IActor[];
  @Prop() loading!: boolean;

  @Emit() removeActor() {}
  @Emit() openMessages() {}

  public mediaBase = APP_CONFIG.media.baseUrl;
  public shareOpened = false;

  public toggleShare() {
    this.shareOpened = !this.shareOpened;
  }
}
