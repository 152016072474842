import Vue from 'vue';
import App from './app.vue';
import appStore from '@/_core/store/app-store.config';
import vuetify from './plugins/vuetify';
import router from '@/app-routes';
import backendless from 'backendless';
import { APP_CONFIG } from '@/_core/constants/app.config';

import '@babel/polyfill';

backendless.initApp(APP_CONFIG.backendless.appId, APP_CONFIG.backendless.apiKey);

Vue.config.productionTip = false;

new Vue({
  router,
  store: appStore,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
