import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { Module } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { IActorConvState, initialState } from '@/modules/actor/connections/store/state';

export const actorConversationsStoreModule: Module<IActorConvState, IRootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
