




































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { ILocationForm } from '@/modules/details/models/actor-stepper-form.model';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { VForm } from '@/_shared/types/v-form';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { IPlainObject } from '@/_shared/models/plain-object.model';

@Component({ name: 'location', components: { StepperFooter } })
export default class Location extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return {
      city: this.form.fields.city,
      country: this.form.fields.country,
      location: {
        type: 'Point',
        coordinates: [this.locationData.latitude, this.locationData.longitude],
      },
    };
  }

  @Emit('onBack') onBack() {}

  private locationData!: IPlainObject;

  public form: ILocationForm = {
    valid: false,
    fields: {
      location: '',
      city: '',
      country: '',
    },
    rules: {
      location: [FormRules.required('Location')],
      city: [FormRules.required('City')],
      country: [FormRules.required('Country')],
    },
  };

  public getAddressData(data: IPlainObject) {
    this.form.fields.country = data.country;
    this.form.fields.city = data.name;
    this.locationData = data;
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onStepChanged();
    }
  }
}
