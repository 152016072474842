import { GetterTree } from 'vuex';
import { IAuthState } from '@/_core/store/auth/state';
import { IRootState } from '@/_core/models/root-state.model';
import { IUser } from '@/_core/models/user.model';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';

export const getters: GetterTree<IAuthState, IRootState> = {
  isAuthenticated: (state: IAuthState): boolean => {
    return !!state.user;
  },
  user: (state: IAuthState): IUser => {
    return state.user;
  },
  isLoading: (state: IAuthState): boolean => {
    return state.isLoading;
  },
  initialLoading: (state: IAuthState): boolean => {
    return state.initialLoading;
  },
  updateLoading: (state: IAuthState): boolean => {
    return state.updateLoading;
  },
  roleSetting: (state: IAuthState): IActorRoleSetting[] => {
    return state.roleSettings;
  },
  emailConfirm: (state: IAuthState): boolean => {
    return state.emailConfirm;
  },
};
