

































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { ILoginForm } from '@/modules/login/models/login-form.model';
import { FormRules } from '@/_shared/utils/form-rules.util';

@Component({ name: 'login-form' })
export default class LoginForm extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(String) readonly btnText!: string;

  @Emit('onCreate') onCreate() {
    return this.form.fields;
  }

  public form: ILoginForm = {
    valid: false,
    fields: {
      email: '',
      password: '',
    },
    rules: {
      email: [FormRules.required('Email'), FormRules.validEmail('Email')],
      password: [FormRules.required('Password'), FormRules.minLength('Password', 5)],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onCreate();
    }
  }
}
