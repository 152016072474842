































import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { AuthActionsTypes } from '@/_core/store/auth/types';

@Component({ name: 'director-account' })
export default class DirectorAccount extends Vue {
  @APP_NAMESPACES.auth.Action(AuthActionsTypes.LOGOUT) logout: () => void;
}
