import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export interface ICastingsState {
  roles: ICastingRole[];
  conversations: IConversation[];
  roleDetails: ICastingRole;
  isLoading: boolean;
  isDetailLoading: boolean;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
}

export const initialState: ICastingsState = {
  roles: [],
  conversations: [],
  roleDetails: null,
  isLoading: false,
  isDetailLoading: false,
  isCreateLoading: false,
  isUpdateLoading: false,
};
