



















import { Component, Vue, Watch } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';
import ConnectionHeader from '@/modules/actor/connections/components/connection-header/connection-header.vue';
import ConnectionFooter from '@/modules/actor/connections/components/connection-footer/connection-footer.vue';
import ConnectionMessages from '@/modules/actor/connections/components/connection-messages/connection-messages.vue';
import { IUser } from '@/_core/models/user.model';
import {
  IOwnerSendMessage,
  ISendMessage,
} from '@/modules/director/casting-calls/models/casting-calls-messages.model';
import { ActorConvActionsTypes } from '@/modules/actor/connections/store/types';

@Component({
  name: 'connection-details',
  components: { ConnectionHeader, ConnectionFooter, ConnectionMessages },
})
export default class ConnectionDetails extends Vue {
  @APP_NAMESPACES.actorConv.Getter('roles') roles!: IConversation[];
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;

  @APP_NAMESPACES.actorConv.Action(ActorConvActionsTypes.SEND_MESSAGE) sendMessage: (
    data: IOwnerSendMessage
  ) => void;

  @Watch('$route.params.id', { immediate: false, deep: false })
  private onRouteChanged() {}

  public get conversation(): IConversation {
    return this.roles.find((role) => role.objectId === this.$route.params.id);
  }

  public onSendMessage(data: ISendMessage) {
    this.sendMessage({ ...data, ownerId: this.user.ownerId });
  }
}
