










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUser } from '@/_core/models/user.model';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import moment from 'moment';

@Component({
  name: 'connection-header',
  filters: {
    createdTime(time: number): string {
      return moment(time).format('MM-DD-YYYY');
    },
  },
})
export default class ConnectionHeader extends Vue {
  @Prop() casting_director: IUser;
  @Prop() role: ICastingRole;

  public get initials() {
    const { firstName, lastName } = this.casting_director;
    return firstName[0] + lastName[0];
  }

  public detailsOpened = false;

  public onToggleDetails() {
    this.detailsOpened = !this.detailsOpened;
  }
}
