import { MutationTree } from 'vuex';
import { CastingsSearchMutationTypes } from './types';
import { ICastingSearchState } from '@/_core/store/casting-search/state';
import { ICastingSearch } from '@/_shared/models/casting-search.model';
import { IActor } from '@/_shared/models/actor.model';

export const mutations: MutationTree<ICastingSearchState> = {
  [CastingsSearchMutationTypes.SET_CREATE_LOADING](state: ICastingSearchState, payload: boolean) {
    state.isCreateLoading = payload;
  },
  [CastingsSearchMutationTypes.SET_LOADING](state: ICastingSearchState, payload: boolean) {
    state.isLoading = payload;
  },
  [CastingsSearchMutationTypes.SET_SEARCH](state: ICastingSearchState, payload: ICastingSearch) {
    state.search = payload;
  },
  [CastingsSearchMutationTypes.SET_ACTORS](state: ICastingSearchState, payload: IActor[]) {
    state.actors = payload;
  },
  [CastingsSearchMutationTypes.ADD_ACTOR](state: ICastingSearchState, payload: IActor) {
    state.selectedActors = [...state.selectedActors, payload];
  },
  [CastingsSearchMutationTypes.SET_CONVERSATIONS_LOADING](state: ICastingSearchState, payload: boolean) {
    state.isConversationsLoading = payload;
  },
  [CastingsSearchMutationTypes.SET_FIND_LOADING](state: ICastingSearchState, payload: boolean) {
    state.isFindLoading = payload;
  },
  [CastingsSearchMutationTypes.REMOVE_ACTOR](state: ICastingSearchState, payload: IActor) {
    state.selectedActors = state.selectedActors.filter(
      (actor) => actor.objectId !== payload.objectId
    );
  },
  [CastingsSearchMutationTypes.RESET](state: ICastingSearchState) {
    state.isCreateLoading = false;
    state.isLoading = false;
    state.search = null;
    state.actors = [];
    state.selectedActors = [];
    state.isConversationsLoading = false;
  },
};
