
































import { Component, Vue } from 'vue-property-decorator';
import MasterCardIcon from '@/assets/icons/master-card.svg';

@Component({ name: 'card-info', components: { MasterCardIcon } })
export default class CardInfo extends Vue {}
