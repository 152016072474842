





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'cl-tab' })
export default class ClTab extends Vue {
  @Prop() title: string;
  @Prop() isActive: boolean;

  private activeTab = false;

  public get isActiveTab(): boolean {
    return this.activeTab;
  }

  public set isActiveTab(state: boolean) {
    this.activeTab = state;
  }
}
