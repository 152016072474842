import { MutationTree } from 'vuex';
import { CastingsMutationTypes } from './types';
import { ICastingsState } from '@/_core/store/casting-calls/state';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export const mutations: MutationTree<ICastingsState> = {
  [CastingsMutationTypes.SET_CASTINGS](state: ICastingsState, payload: ICastingRole[]) {
    state.isLoading = false;
    state.roles = payload;
  },
  [CastingsMutationTypes.SET_LOADING](state: ICastingsState, payload: boolean) {
    state.isLoading = payload;
  },
  [CastingsMutationTypes.SET_DETAILS_LOADING](state: ICastingsState, payload: boolean) {
    state.isDetailLoading = payload;
  },
  [CastingsMutationTypes.SET_CREATE_LOADING](state: ICastingsState, payload: boolean) {
    state.isCreateLoading = payload;
  },
  [CastingsMutationTypes.ADD_MESSAGE](state: ICastingsState, payload: any) {
    state.conversations = state.conversations.map((conv) => {
      return conv.objectId !== payload.conversationId
        ? conv
        : {
            ...conv,
            messages: [...conv.messages, payload.message],
          };
    });
  },
  [CastingsMutationTypes.SET_UPDATE_LOADING](state: ICastingsState, payload: boolean) {
    state.isUpdateLoading = payload;
  },
  [CastingsMutationTypes.SET_DETAILS](
    state: ICastingsState,
    payload: { details: ICastingRole; conversations: IConversation[] }
  ) {
    state.roleDetails = payload.details;
    state.conversations = payload.conversations;
  },
  [CastingsMutationTypes.ARCHIVE](state: ICastingsState, id: string) {
    state.conversations = state.conversations.map((conv) => {
      return conv.objectId === id ? { ...conv, archived: !conv.archived } : conv;
    });
  },
  [CastingsMutationTypes.UPDATE_DETAILS](state: ICastingsState, updatedRole: ICastingRole) {
    state.roles = state.roles.map((role) =>
      role.objectId === updatedRole.objectId ? updatedRole : role
    );
    state.roleDetails = { ...state.roleDetails, ...updatedRole };
  },
  [CastingsMutationTypes.RESET_DETAILS](state: ICastingsState) {
    state.roleDetails = null;
    state.isDetailLoading = false;
  },
  [CastingsMutationTypes.RESET](state: ICastingsState) {
    state.roleDetails = null;
    state.isDetailLoading = false;
    state.roles = [];
    state.isLoading = false;
  },
};
