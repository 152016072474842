








































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import LocationIcon from '@/assets/icons/location.svg';
import PhotosIcon from '@/assets/icons/photos.svg';
import PlayerIcon from '@/assets/icons/player.svg';
import RecommendedIcon from '@/assets/icons/recommended.svg';
import { IActor } from '@/_shared/models/actor.model';
import { APP_CONFIG } from '@/_core/constants/app.config';

@Component({
  name: 'actor-card',
  components: { LocationIcon, PhotosIcon, PlayerIcon, RecommendedIcon },
})
export default class ActorCard extends Vue {
  @Prop() actor!: IActor;
  @Prop() selectedActors!: IActor[];

  @Emit('onAddToShortList') onAddToShortList() {}
  @Emit('onOpenDetails') onOpenDetails() {}
  @Emit('onRemove') onRemove() {}

  public mediaBase = APP_CONFIG.media.baseUrl;

  public get isSelected() {
    return this.selectedActors.some((actor: any) => actor.objectId === this.actor.objectId);
  }

  public putActorInShortlist() {
    return this.isSelected ? this.onRemove() : this.onAddToShortList();
  }
}
