import { mutations } from './mutations';
import { Module } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { IDetailsState, initialState } from '@/_core/store/details/state';
import { getters } from '@/_core/store/details/getters';
import { actions } from '@/_core/store/details/actions';

export const detailsStoreModule: Module<IDetailsState, IRootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
