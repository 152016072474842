




















































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { FormRules } from '@/_shared/utils/form-rules.util';
import {
  IAddCastingCall,
  IAddCastingCallForm,
} from '@/modules/director/casting-calls/models/add-casting-call.model';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { CastingsActionsTypes } from '@/_core/store/casting-calls/types';

@Component({ name: 'add-casting-call' })
export default class CastingCallsAdd extends Vue {
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.CREATE) create: (
    payload: IAddCastingCall
  ) => void;
  @APP_NAMESPACES.castings.Getter('isCreateLoading') isCreateLoading!: boolean;

  @Ref('formRef') readonly formRef!: VForm;

  public form: IAddCastingCallForm = {
    valid: false,
    fields: {
      name: '',
      description: '',
    },
    rules: {
      name: [FormRules.required('Casting Name')],
      description: [FormRules.required('Casting Description')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.create(this.form.fields);
    }
  }
}
