









import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';
import { UserRole } from '@/_core/constants/user-role.const';
import DetailsForm from './components/director-detail-form/director-detail-form.vue';
import ActorStepperForm from './components/actor-stepper-form/actor-stepper-form.vue';

@Component({
  name: 'details',
  components: { DetailsForm, ActorStepperForm },
})
export default class Details extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;

  get UserRole() {
    return UserRole;
  }
}
