import { namespace } from 'vuex-class';
import { AUTH_NAMESPACE } from '@/_core/store/auth/types';
import { DETAILS_NAMESPACE } from '@/_core/store/details/types';
import { CASTINGS_NAMESPACE } from '@/_core/store/casting-calls/types';
import { CASTING_SEARCH_NAMESPACE } from '@/_core/store/casting-search/types';
import { ACTOR_CONVERSATIONS_NAMESPACE } from '@/modules/actor/connections/store/types';

export const APP_NAMESPACES = {
  auth: namespace(AUTH_NAMESPACE),
  details: namespace(DETAILS_NAMESPACE),
  castings: namespace(CASTINGS_NAMESPACE),
  castingSearch: namespace(CASTING_SEARCH_NAMESPACE),
  actorConv: namespace(ACTOR_CONVERSATIONS_NAMESPACE),
};
