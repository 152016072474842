
























































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { IPersonalDataForm } from '@/modules/details/models/actor-stepper-form.model';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { VForm } from '@/_shared/types/v-form';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';

@Component({ name: 'personal-data', components: { StepperFooter } })
export default class PersonalData extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return this.form.fields;
  }

  public form!: IPersonalDataForm;

  public created() {
    this.form = {
      valid: false,
      fields: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        gender: this.user.gender
      },
      rules: {
        firstName: [FormRules.required('First Name'), FormRules.minLength('First', 2)],
        lastName: [FormRules.required('Last Name'), FormRules.minLength('Last', 2)],
        gender: [FormRules.required('Gender')],
      },
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onStepChanged();
    }
  }
}
