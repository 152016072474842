











































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'cl-mailchimp',
})
export default class ClMailchimp extends Vue {}
