
















































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IExperienceForm, IRole } from '@/modules/details/models/actor-stepper-form.model';
import { ROLE_INITIAL } from '@/modules/details/constants/experience-step.const';
import { FormRules } from '@/_shared/utils/form-rules.util';

@Component({
  name: 'actor-experience-form',
})
export default class ActorExperienceForm extends Vue {
  @Prop() experience!: IRole[];
  @Prop() loading: boolean;

  @Emit() save() {
    return this.form.fields;
  }

  @Ref('formRef') readonly formRef!: VForm;

  public form: IExperienceForm = {
    valid: false,
    fields: {
      experience: [{ ...ROLE_INITIAL }],
    },
    rules: {
      type: [FormRules.required('Type')],
      name: [FormRules.required('Name')],
      year: [FormRules.required('')],
      role: [FormRules.required('Role')],
      studio: [FormRules.required('Studio')],
      director: [FormRules.required('Director')],
    },
  };

  public mounted() {
    this.form.fields.experience = this.experience;
  }

  public onAddRole() {
    this.form = {
      ...this.form,
      fields: {
        ...this.form.fields,
        experience: [...this.form.fields.experience, { ...ROLE_INITIAL }],
      },
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.save();
    }
  }

  public onDelete(index: number) {
    this.form.fields.experience = this.form.fields.experience.filter((_, i) => index !== i);
  }
}
