import { ActionTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingsState } from '@/_core/store/casting-calls/state';
import { CastingsActionsTypes, CastingsMutationTypes } from '@/_core/store/casting-calls/types';
import backendless from 'backendless';
import router from '@/app-routes';
import { IAddCastingCall } from '@/modules/director/casting-calls/models/add-casting-call.model';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import {
  IChatMessage,
  IConversation,
} from '@/modules/director/casting-calls/models/chat-message.model';
import { handleApiError } from '@/_shared/utils/error-handler.util';

export const actions: ActionTree<ICastingsState, IRootState> = {
  async [CastingsActionsTypes.FETCH]({ commit }) {
    commit(CastingsMutationTypes.SET_LOADING, true);

    try {
      const roles: ICastingRole[] = await backendless.Data.of('roles').find({
        pageSize: 100,
        sortBy: ['-created'],
      });

      commit(CastingsMutationTypes.SET_CASTINGS, roles);
      commit(CastingsMutationTypes.SET_LOADING, false);

      if (roles.length) {
        const routeId = router.currentRoute.params.id || roles[0].objectId;
        router.push(`/casting-calls/discussions/${routeId}/messages`).catch(() => {});
      }
    } catch (e) {
      commit(CastingsMutationTypes.SET_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsActionsTypes.FETCH_DETAILS]({ commit }, payload: string) {
    commit(CastingsMutationTypes.SET_DETAILS_LOADING, true);

    try {
      const roleDetails: ICastingRole[] = await backendless.Data.of('roles').find({
        relations: ['actors', 'conversations', 'search_history'],
        relationsDepth: 3,
      });

      let conversations: IConversation[] = await backendless.Data.of('conversations').find({
        relations: ['messages', 'actor'],
        relationsDepth: 3,
        relationsPageSize: 99,
      });

      conversations = conversations.map((conv: IConversation) => ({
        ...conv,
        messages: conv.messages.sort((a: IChatMessage, b: IChatMessage) => a.created - b.created),
      }));

      commit(CastingsMutationTypes.SET_DETAILS, {
        details: roleDetails[0],
        conversations,
      });
      commit(CastingsMutationTypes.SET_DETAILS_LOADING, false);
    } catch (e) {
      commit(CastingsMutationTypes.SET_DETAILS_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsActionsTypes.CREATE]({ commit, dispatch }, payload: IAddCastingCall) {
    commit(CastingsMutationTypes.SET_CREATE_LOADING, true);

    try {
      const detail: ICastingRole = await backendless.Data.of('roles').save<ICastingRole>(payload);

      router.push(`/casting-calls/discussions/${detail.objectId}`);

      commit(CastingsMutationTypes.SET_CREATE_LOADING, false);
    } catch (e) {
      commit(CastingsMutationTypes.SET_CREATE_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsActionsTypes.UPDATE_DETAILS](
    { commit, dispatch },
    payload: { objectId: string; data: Partial<ICastingRole> }
  ) {
    commit(CastingsMutationTypes.SET_UPDATE_LOADING, true);

    try {
      const [detailed]: ICastingRole[] = await backendless.Data.of('roles').find<ICastingRole>({
        where: `objectId = '${payload.objectId}'`,
      });

      for (const key in payload.data) {
        if (detailed.hasOwnProperty(key) && payload.data.hasOwnProperty(key)) {
          // @ts-ignore
          detailed[key] = payload.data[key];
        }
      }

      const updated = await backendless.Data.of('roles').save(detailed);

      commit(CastingsMutationTypes.UPDATE_DETAILS, updated);
      commit(CastingsMutationTypes.SET_UPDATE_LOADING, false);
    } catch (e) {
      commit(CastingsMutationTypes.SET_UPDATE_LOADING, false);
      handleApiError(e);
    }
  },
  async [CastingsActionsTypes.SEND_MESSAGE]({ commit, rootState }, payload) {
    try {
      commit(CastingsMutationTypes.ADD_MESSAGE, {
        conversationId: payload.convId,
        message: {
          message: payload.message,
          created: Date.now(),
          ownerId: payload.ownerId,
          type: payload.type,
        },
      });

      const message: IChatMessage = await backendless.Data.of('chat_messages').save<IChatMessage>({
        message: payload.message,
        type: payload.type,
      });
      await backendless.Data.of('chat_messages').setRelation(message, 'conversation_id', [
        payload.convId,
      ]);
      await backendless.Data.of('conversations').addRelation(payload.convId, 'messages', [
        message.objectId,
      ]);
    } catch (e) {
      handleApiError(e);
    }
  },
  async [CastingsActionsTypes.ARCHIVE](
    { commit },
    payload: { objectId: string; archived: boolean }
  ) {
    commit(CastingsActionsTypes.ARCHIVE, payload.objectId);
    await backendless.Data.of('conversations').save(payload);
  },
};
