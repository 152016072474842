












import { Component, Vue } from 'vue-property-decorator';
import ActorDesireForm from '@/modules/actor/resume/components/actor-desire-form/actor-desire-form.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';
import { AuthActionsTypes } from '@/_core/store/auth/types';

@Component({
  name: 'actor-restrictions',
  components: { ActorDesireForm },
})
export default class ActorRestrictions extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
  @APP_NAMESPACES.auth.Getter('updateLoading') updateLoading!: boolean;
  @APP_NAMESPACES.auth.Getter('roleSetting') roleSetting!: IActorRoleSetting[];

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.UPDATE_USER) updateUser: (
    data: Partial<IUser>
  ) => void;

  public getBySetting(type: string) {
    return this.roleSetting.filter((s) => s.type === type);
  }
}
