



















































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { ISkillsForm } from '@/modules/details/models/actor-stepper-form.model';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { FormRules } from '@/_shared/utils/form-rules.util';
import backendless from 'backendless';

@Component({ name: 'skills', components: { StepperFooter } })
export default class Skills extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return {
      ...this.form.fields,
      skills: this.form.fields.skills.map((skill) =>
        this.items.find((item: any) => item.name === skill)
      ),
    };
  }

  @Emit('onBack') onBack() {}

  public items: string[] = [];
  public form: ISkillsForm = {
    valid: false,
    fields: {
      skills: [],
    },
    rules: {
      skills: [FormRules.notEmptyArray('Skills')],
    },
  };

  public removeSkill(item: string) {
    this.form = {
      ...this.form,
      fields: {
        ...this.form.fields,
        skills: [...this.form.fields.skills.splice(this.form.fields.skills.indexOf(item), 1)],
      },
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onStepChanged();
    }
  }

  public async mounted() {
    this.items = await backendless.Data.of('skills').find();
  }
}
