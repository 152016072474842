



































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ActorMessage from '@/modules/director/casting-calls/components/actor-message/actor-message.vue';
import EyeIcon from '@/assets/icons/eye.svg';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import ClReminder from '@/_shared/components/cl-reminder/cl-reminder.vue';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';
import { CastingsActionsTypes } from '@/_core/store/casting-calls/types';
import { IUser } from '@/_core/models/user.model';
import {
  IOwnerSendMessage,
  ISendMessage,
} from '@/modules/director/casting-calls/models/casting-calls-messages.model';
import ShareShortlist from '@/modules/director/casting-calls/components/share-shortlist/share-shortlist.vue';
import { CastingsSearchActionsTypes } from '@/_core/store/casting-search/types';
import { ITag } from '@/_shared/models/tag.model';

@Component({
  name: 'casting-calls-message',
  components: { ClReminder, ActorMessage, EyeIcon, ShareShortlist },
})
export default class CastingCallsMessages extends Vue {
  @APP_NAMESPACES.castingSearch.Action(CastingsSearchActionsTypes.CREATE) createSearch!: (
    payload: unknown
  ) => void;
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.SEND_MESSAGE) sendMessage: (
    data: IOwnerSendMessage
  ) => void;
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.ARCHIVE) archive: (data: {
    objectId: string;
    archived: boolean;
  }) => void;

  @APP_NAMESPACES.castings.Getter('roleDetails') roleDetails!: ICastingRole;
  @APP_NAMESPACES.castings.Getter('conversations') conversations!: IConversation[];
  @APP_NAMESPACES.castings.Getter('archivedConversations') archivedConversations!: IConversation[];
  @APP_NAMESPACES.auth.Getter('user') user: IUser;
  @APP_NAMESPACES.castingSearch.Getter('isCreateLoading') isLoading!: boolean;

  @Watch('$route.params.id', { immediate: false, deep: false })
  private onRouteChanged() {
    this.roleId = this.$route.params.id;
  }

  public roleId = this.$route.params.id;
  public showArchived = true;
  public dialogVisible = {
    share: false,
    messageAll: false,
  };

  public onToggleArchived(state: boolean) {
    this.showArchived = state;
  }

  public onSendMessage(data: ISendMessage) {
    this.sendMessage({ ...data, ownerId: this.user.ownerId });
  }

  public onTogglePopup(key: string, state: boolean) {
    this.dialogVisible = {
      ...this.dialogVisible,
      [key]: state,
    };
  }

  public onCreateSearch() {
    const mockForm = {
      name: 'Initial search',
      fullLocation: 'New York, NY, USA',
      location: 'New York',
      actorType: 'eligible',
      tags: [
        {
          created: 1612608873000,
          name: 'Lawyer',
          ___class: 'tags',
          type: 'job',
          ownerId: 123,
          updated: 123,
          objectId: 'C39105C7-A5F8-4017-8F46-E90159730A0A',
          group: 'skills',
        },
        {
          created: 1612608873000,
          name: 'Intelligent',
          ___class: 'tags',
          type: 'trait',
          ownerId: 123,
          updated: 123,
          objectId: 'B14CD738-778A-4251-B279-70BAA05CAE95',
          group: 'skills',
        },
        {
          created: 1612608873000,
          name: 'Fit',
          ___class: 'tags',
          type: 'physical',
          ownerId: 123,
          updated: 123,
          objectId: 'B44B451E-BD6D-4E31-9600-55338D08D486',
          group: 'skills',
        },
      ],
    };

    this.createSearch({ roleId: this.roleId, form: mockForm });
  }

  public onArchive(payload: { objectId: string; archived: boolean }) {
    this.archive(payload);
  }

  public onShareShortlist() {
    console.log('Share shortlist');
  }
}
