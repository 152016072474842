















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';
import { IPlainObject } from '@/_shared/models/plain-object.model';

@Component({
  name: 'actor-desire-form',
})
export default class ActorDesireForm extends Vue {
  @Prop() items!: IActorRoleSetting[];
  @Prop() type!: string;
  @Prop() loading!: boolean;
  @Prop() userSettings!: IPlainObject;

  @Emit() save() {
    return { [this.type]: this.checkboxes };
  }

  public checkboxes = {};

  public mounted() {
    this.items.forEach((item) => {
      this.checkboxes = {
        ...this.checkboxes,
        [item.name]: this.userSettings && this.userSettings[item.name],
      };
    });
  }
}
