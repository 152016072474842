

































































































































import { Component, Vue } from 'vue-property-decorator';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import analytic from '@/_core/services/analytic.service';
import ClSnackbar from '@/_shared/components/cl-snackbar/cl-snackbar.vue';

@Component({
  name: 'dashboard',
  components: { StripeCheckout, ClSnackbar },
})
export default class Dashboard extends Vue {
  public snackbar = false;

  public mounted() {
    analytic.track(`Navigation: Resume`);
  }

  public onCopy() {
    navigator.clipboard.writeText('http://beta.custle.co/').then(() => {
      this.snackbar = true;
    });
  }
}
