




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUser } from '@/_core/models/user.model';
import { APP_CONFIG } from '@/_core/constants/app.config';

@Component({ name: 'cl-actor-cv' })
export default class ClActorCv extends Vue {
  @Prop() readonly user: IUser;

  public mediaBase = APP_CONFIG.media.baseUrl;

  public get userPhoto(): string {
    const uri =
      typeof this.user.mainPhoto === 'string'
        ? this.user.mainPhoto
        : this.user.mainPhoto && this.user.mainPhoto.uri;
    return `url(${this.mediaBase}/${uri})`;
  }

  public get userSkills(): string {
    return this.user.skills.map((skill) => skill.name).join(', ');
  }
}
