






import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';

@Component({
  name: 'actor-showreels',
})
export default class ActorShowreels extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
}
