


















import ClHeader from '@/_core/components/cl-header/cl-header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { AuthActionsTypes, AuthMutationTypes } from '@/_core/store/auth/types';
import { IUser } from '@/_core/models/user.model';
import AppLoader from '@/assets/icons/app-loader.svg';

@Component({
  components: { ClHeader, AppLoader },
})
export default class App extends Vue {
  @APP_NAMESPACES.auth.Getter('isAuthenticated') isAuthenticated!: boolean;
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
  @APP_NAMESPACES.auth.Getter('initialLoading') initialLoading!: boolean;

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.GET_USER) getUser: () => void;
  @APP_NAMESPACES.auth.Action(AuthActionsTypes.LOGOUT) logout: () => void;

  @APP_NAMESPACES.auth.Mutation(AuthMutationTypes.SET_USER) loginSuccess: (data: IUser) => void;

  public created() {
    this.getUser();
  }

  public onLogout() {
    this.logout();
  }
}
