














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import { PhotoSwipe, PhotoSwipeGallery } from 'v-photoswipe';
import { APP_CONFIG } from '@/_core/constants/app.config';
import { IMediaPhoto } from '@/_core/models/media_photo.model';

@Component({
  name: 'cl-photo-gallery',
  components: {
    'v-photoswipe': PhotoSwipe,
    'v-photoswipe-gallery': PhotoSwipeGallery,
  },
})
export default class ClPhotoGallery extends Vue {
  @Prop() images: object[];
  @Prop() index = 0;
  @Prop() opened = false;

  @Emit('close') close() {
  }

  public mediaBase = APP_CONFIG.media.baseUrl;
  public options = { index: 0 };

  public get photos() {
    return this.images.map((item: Partial<IMediaPhoto>) => ({
      src: `${this.mediaBase}/${item.uri}`,
      w: 1600,
      h: 1600,
      title: '',
    }));
  }
}
