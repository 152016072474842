import { MutationTree } from 'vuex';
import { IAuthState } from '@/_core/store/auth/state';
import { AuthMutationTypes } from './types';
import { IUser } from '@/_core/models/user.model';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';

export const mutations: MutationTree<IAuthState> = {
  [AuthMutationTypes.SET_LOADING](state: IAuthState, payload: boolean) {
    state.isLoading = payload;
  },
  [AuthMutationTypes.SET_USER](state: IAuthState, payload: IUser) {
    state.user = payload;
    state.isLoading = false;
    state.updateLoading = false;
    localStorage.setItem('user', JSON.stringify(payload));
  },
  [AuthMutationTypes.LOGIN_FAILED](state: IAuthState) {
    state.user = null;
    state.isLoading = false;
    state.isLoading = false;
    localStorage.clear();
  },
  [AuthMutationTypes.SET_INITIAL_LOADING](state: IAuthState, payload: boolean) {
    state.initialLoading = payload;
  },
  [AuthMutationTypes.UPDATE_USER_LOADING](state: IAuthState, payload: boolean) {
    state.updateLoading = payload;
  },
  [AuthMutationTypes.SET_ROLE_SETTINGS](state: IAuthState, payload: IActorRoleSetting[]) {
    state.roleSettings = payload;
  },
  [AuthMutationTypes.SET_EMAIL_CONFIRM](state: IAuthState, payload: boolean) {
    state.emailConfirm = payload;
  },
  [AuthMutationTypes.RESET](state: IAuthState) {
    state.user = null;
    state.isLoading = false;
    state.updateLoading = false;
    state.initialLoading = false;
    state.roleSettings = [];
  },
};
