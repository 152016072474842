





















import ShareIcon from '@/assets/icons/share.svg';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { CastingsActionsTypes, CastingsMutationTypes } from '@/_core/store/casting-calls/types';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';

@Component({ name: 'casting-calls-details', components: { ClSpinner, ShareIcon } })
export default class CastingCallsDetail extends Vue {
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.FETCH_DETAILS) fetchDetail: (
    id: string
  ) => void;
  @APP_NAMESPACES.castings.Mutation(CastingsMutationTypes.RESET_DETAILS) resetDetails: () => void;
  @APP_NAMESPACES.castings.Getter('isDetailLoading') isDetailLoading: boolean;

  @Watch('$route.params.id', { immediate: false, deep: false })
  private onRouteChanged() {
    this.resetDetails();
    this.fetchDetail(this.$route.params.id);
  }

  public created() {
    this.fetchDetail(this.$route.params.id);
  }

  public destroyed() {
    this.resetDetails();
  }
}
