import backendless from 'backendless';
import axios from 'axios';

export const uploadPhoto = async <T = string>(file: File, type = 'image'): Promise<string> => {
  const data = await backendless.BL.CustomServices.invoke('Media', 'upload', {
    filename: file.name,
    type,
  });

  const form = new FormData();
  Object.keys(data.fields).forEach((key) => form.append(key, data.fields[key]));

  form.append('Content-Type', file.type);
  form.append('file', file);

  await axios.post(data.url, form, { headers: { 'Content-Type': file.type } });

  return encodeURI(data.uri);
};
