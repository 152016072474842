


































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { IDirectorProfileForm } from '@/modules/director/director-account/models/director-profile-form.model';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { updateForm } from '@/_shared/utils/update-form.util';
import { APP_CONFIG } from '@/_core/constants/app.config';
import { AuthActionsTypes } from '@/_core/store/auth/types';

@Component({ name: 'director-details' })
export default class DirectorDetails extends Vue {
  @APP_NAMESPACES.auth.Action(AuthActionsTypes.UPDATE_USER) updateUser: (
    data: Partial<IUser>
  ) => void;
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;

  @Ref('formRef') readonly formRef!: VForm;

  public mediaBase = APP_CONFIG.media.baseUrl;
  public form: IDirectorProfileForm = {
    valid: false,
    fields: {
      firstName: this.user && this.user.firstName,
      lastName: this.user && this.user.lastName,
      email: this.user && this.user.email,
      companyName: this.user && this.user.companyName,
      website: this.user && this.user.website,
      country: this.user && this.user.country,
      phone: this.user && this.user.phone,
    },
    rules: {
      firstName: [FormRules.required('First Name'), FormRules.minLength('First Name', 5)],
      lastName: [FormRules.required('Last Name'), FormRules.minLength('Last Name', 5)],
      companyName: [FormRules.required('Company Name')],
      website: [FormRules.required('Website'), FormRules.validWebsite('Website')],
      country: [FormRules.required('Country')],
      email: [FormRules.required('Email'), FormRules.validEmail('Email')],
      phone: [FormRules.required('Phone')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.updateUser(this.form.fields);
    }
  }

  public created() {
    this.form.fields = updateForm(this.form.fields, this.user) as any;
  }

  public onDeleteAccount() {
    alert('Nope');
  }
}
