import amplitude from 'amplitude-js';

class Analytic {
  private instance!: any;

  public init(userId: string) {
    if (!this.instance) {
      // @ts-ignore
      amplitude.getInstance().init(window.APP_CONFIG.AMP_KEY, userId);
    }
  }

  public track(name: string) {
    amplitude.getInstance().logEvent(name);
  }
}

const analytic = new Analytic();
export default analytic;
