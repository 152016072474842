// @ts-nocheck
export const APP_CONFIG = {
  backendless: {
    appId: window.APP_CONFIG.backendlessAppId,
    apiKey: window.APP_CONFIG.backendlessApiKey,
  },
  google: {
    apiKey: window.APP_CONFIG.googleApiKey,
  },
  media: {
    baseUrl: window.APP_CONFIG.mediaBaseUrl,
  },
};
