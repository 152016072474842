











import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';
import ActorExperienceForm from '../../components/actor-experience/actor-experience.vue';
import { AuthActionsTypes } from '@/_core/store/auth/types';

@Component({
  name: 'actor-experience',
  components: { ActorExperienceForm },
})
export default class ActorExperience extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
  @APP_NAMESPACES.auth.Getter('updateLoading') updateLoading!: boolean;

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.UPDATE_USER) updateUser: (
    data: Partial<IUser>
  ) => void;
}
