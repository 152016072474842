


















































import { Component, Vue, Watch } from 'vue-property-decorator';
import CastingShortlist from '../../components/casting-shortlist/casting-shortlist.vue';
import ActorCard from '../../components/actor-card/actor-card.vue';
import CastingSearchAside from '@/modules/director/casting-calls/components/casting-search-aside/casting-search-aside.vue';
import ActorDetailPopup from '@/modules/director/casting-calls/components/actor-detail-popup/actor-detail-popup.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import {
  CastingsSearchActionsTypes,
  CastingsSearchMutationTypes,
} from '@/_core/store/casting-search/types';
import { ICastingSearch } from '@/_shared/models/casting-search.model';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';
import { IActor } from '@/_shared/models/actor.model';
import { ICreateSearch } from '@/modules/director/create-search/models/create-search.model';

@Component({
  name: 'casting-calls-search',
  components: { ClSpinner, ActorDetailPopup, CastingShortlist, ActorCard, CastingSearchAside },
})
export default class CastingCallsSearch extends Vue {
  @APP_NAMESPACES.castingSearch.Action(CastingsSearchActionsTypes.FIND_ACTORS)
  findActors!: (payload: { search: ICreateSearch; roleId: string }) => void;
  @APP_NAMESPACES.castingSearch.Action(CastingsSearchActionsTypes.CREATE_MESSAGES)
  createMessages!: (payload: { actors: string[]; roleId: string }) => void;
  @APP_NAMESPACES.castingSearch.Action(CastingsSearchActionsTypes.FETCH) fetch!: (data: {
    searchId: string;
    roleId: string;
    skip?: boolean;
  }) => void;

  @APP_NAMESPACES.castingSearch.Getter('search') search!: ICastingSearch;
  @APP_NAMESPACES.castingSearch.Getter('actors') actors!: IActor[];
  @APP_NAMESPACES.castingSearch.Getter('selectedActors') selectedActors!: IActor[];
  @APP_NAMESPACES.castingSearch.Getter('findLoading') findLoading!: boolean;
  @APP_NAMESPACES.castingSearch.Getter('isConversationsLoading') isConversationsLoading!: boolean;

  @APP_NAMESPACES.castingSearch.Mutation(CastingsSearchMutationTypes.ADD_ACTOR) addActor!: (
    actor: IActor
  ) => void;
  @APP_NAMESPACES.castingSearch.Mutation(CastingsSearchMutationTypes.REMOVE_ACTOR) removeActor!: (
    actor: IActor
  ) => void;
  @APP_NAMESPACES.castingSearch.Mutation(CastingsSearchMutationTypes.RESET) reset!: () => void;

  @Watch('$route.params.id', { immediate: false, deep: false })
  private onRouteChanged() {
    this.searchId = this.$route.params.id;
    this.roleId = this.$route.params.roleId;
  }

  private roleId = this.$route.params.roleId;
  private searchId = this.$route.params.id;
  private selectedActor: IActor = null;
  public detailShown = false;

  public onTogglePopup(actor: IActor) {
    this.selectedActor = actor;
    this.detailShown = !this.detailShown;
  }

  public created() {
    this.fetch({ searchId: this.searchId, roleId: this.roleId, skip: true });
  }

  public destroyed() {
    this.reset();
  }

  public onAddToShortList(actor: IActor, state: boolean) {
    return state ? this.addActor(actor) : this.removeActor(actor);
  }

  public onFilterActors(search: ICreateSearch) {
    this.findActors({ search, roleId: this.roleId });
  }

  public onOpenMessages() {
    this.createMessages({
      actors: this.selectedActors.map((actor) => actor.objectId),
      roleId: this.roleId,
    });
  }
}
