import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { IAuthState, initialState } from '@/_core/store/auth/state';
import { Module } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';

export const authStoreModule: Module<IAuthState, IRootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
