








































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import LocationIcon from '@/assets/icons/location.svg';
import { APP_CONFIG } from '@/_core/constants/app.config';
import moment from 'moment';
import {
  IConversation,
  IMessageType,
} from '@/modules/director/casting-calls/models/chat-message.model';
import { uploadPhoto } from '@/_shared/utils/upload-photo.util';

@Component({
  name: 'actor-message',
  components: { LocationIcon },
  filters: {
    updatedAt(time: number): string {
      return moment(time).fromNow();
    },
  },
})
export default class ActorMessage extends Vue {
  @Prop() readonly conversation!: IConversation;
  @Prop() readonly ownerId!: string;
  @Prop(Boolean) readonly disabled!: boolean;

  @Watch('conversation') onMessagesChanged(newValue: IConversation, oldValue: IConversation) {
    if (newValue.messages.length !== oldValue.messages.length) {
      this.onScrollMessages();
    }
  }

  @Emit('sendMessage') sendMessage(message?: string, type = 'message') {
    return {
      message: message || this.message,
      type,
      convId: this.conversation.objectId,
    };
  }

  @Emit('archive') archive() {
    return {
      objectId: this.conversation.objectId,
      archived: !this.conversation.archived,
    };
  }

  public mediaBase = APP_CONFIG.media.baseUrl;
  public isChatOpened = false;
  public message = '';
  public messageType: IMessageType = 'message';

  public get isNote() {
    return this.messageType === 'note';
  }

  public getFirstMessage(): string {
    const lastMessage = this.conversation.messages[this.conversation.messages.length - 1];
    return lastMessage ? lastMessage.message : 'Start your conversation here';
  }

  public onOpenChat() {
    this.isChatOpened = !this.isChatOpened;

    if (this.isChatOpened) {
      this.onScrollMessages();
    }
  }

  public onScrollMessages() {
    setTimeout(() => {
      const el = this.$el.querySelector('.wrapper-chat-messages');
      el.scrollTop = el.scrollHeight;
    }, 0);
  }

  public onChangeMessageType(type: IMessageType) {
    this.messageType = type;
  }

  public onSendMessage() {
    if (!this.message.trim().length) {
      return;
    }

    this.sendMessage();
    this.message = '';
  }

  public async uploadFileMessage(evt: any) {
    const file = evt.target.files[0];
    this.sendMessage(await uploadPhoto(file), 'image');
  }
}
