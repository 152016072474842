import { GetterTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingsState } from '@/_core/store/casting-calls/state';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export const getters: GetterTree<ICastingsState, IRootState> = {
  isLoading: (state: ICastingsState): boolean => {
    return state.isLoading;
  },
  roles: (state: ICastingsState): ICastingRole[] => {
    return state.roles;
  },
  roleDetails: (state: ICastingsState): ICastingRole => {
    return state.roleDetails;
  },
  conversations: (state: ICastingsState): IConversation[] => {
    return state.conversations.filter((conv) => !conv.archived);
  },
  archivedConversations: (state: ICastingsState): IConversation[] => {
    return state.conversations.filter((conv) => conv.archived);
  },
  isDetailLoading: (state: ICastingsState): boolean => {
    return state.isDetailLoading;
  },
  isUpdateLoading: (state: ICastingsState): boolean => {
    return state.isUpdateLoading;
  },
  isCreateLoading: (state: ICastingsState): boolean => {
    return state.isCreateLoading;
  },
};
