import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { Module } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingsState, initialState } from '@/_core/store/casting-calls/state';

export const castingsStoreModule: Module<ICastingsState, IRootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
