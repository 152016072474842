export const CASTINGS_NAMESPACE = 'castings';

export enum CastingsActionsTypes {
  FETCH = '[CASTINGS] LOGIN',
  FETCH_DETAILS = '[CASTINGS] FETCH_DETAILS',
  CREATE = '[CASTINGS] CREATE',
  UPDATE_DETAILS = '[CASTINGS] UPDATE_DETAILS',
  SEND_MESSAGE = '[CASTINGS] ADD_MESSAGE',
  ARCHIVE = '[CASTINGS] ARCHIVE',
}

export enum CastingsMutationTypes {
  SET_LOADING = '[CASTINGS] SET_LOADING',
  SET_CASTINGS = '[CASTINGS] SET_CASTINGS',
  SET_DETAILS = '[CASTINGS] SET_DETAILS',
  SET_DETAILS_LOADING = '[CASTINGS] SET_DETAILS_LOADING',
  SET_CREATE_LOADING = '[CASTINGS] SET_CREATE_LOADING',
  SET_UPDATE_LOADING = '[CASTINGS] SET_UPDATE_LOADING',
  ADD_MESSAGE = '[CASTINGS] ADD_MESSAGE',
  ARCHIVE = '[CASTINGS] ARCHIVE',
  RESET_DETAILS = '[CASTINGS] RESET_DETAILS',
  UPDATE_DETAILS = '[CASTINGS] UPDATE_DETAILS',
  RESET = '[CASTINGS] RESET',
}
