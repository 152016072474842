export class FormRules {
  static required(fieldName = 'This field'): (value: any) => boolean | string {
    return (value) => !!value || value === 0 || `${fieldName} required`;
  }

  static notEmptyArray(fieldName = 'This field'): (value: any) => boolean | string {
    return (value) => !!value.length || `${fieldName} is required`;
  }

  static validEmail(fieldName = 'This field'): (value: any) => boolean | string {
    return (value) => /.+@.+\..+/.test(value) || `${fieldName} must be valid`;
  }

  static validWebsite(fieldName = 'This field'): (value: any) => boolean | string {
    return (value) =>
      /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi.test(
        value
      ) || `${fieldName} must be valid`;
  }

  static minLength(fieldName = 'This field', minLength = 10): (value: any) => boolean | string {
    return (value) =>
      (value && value.length >= minLength) ||
      `${fieldName} must contain at least ${minLength} characters`;
  }

  static validatePhone(fieldName = 'This field'): (value: any) => boolean | string {
    return (value) =>
      /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(value) ||
      `${fieldName} must be valid`;
  }
}
