






























































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { IProjectDetailsForm } from '@/modules/director/casting-calls/models/project-detail.model';
import { VForm } from '@/_shared/types/v-form';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import { updateForm } from '@/_shared/utils/update-form.util';
import moment from 'moment';
import { CastingsActionsTypes } from '@/_core/store/casting-calls/types';
import ClReminder from '@/_shared/components/cl-reminder/cl-reminder.vue';

@Component({ name: 'casting-calls-project', components: { ClReminder } })
export default class CastingCallsProject extends Vue {
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.UPDATE_DETAILS) updateDetails: (
    data: any
  ) => void;
  @APP_NAMESPACES.castings.Getter('roleDetails') roleDetails!: ICastingRole;
  @APP_NAMESPACES.castings.Getter('isUpdateLoading') isUpdateLoading!: boolean;

  @Ref('formRef') readonly formRef!: VForm;

  public castingId = this.$route.params.id;

  public pickers = {
    start: false,
    finish: false,
  };

  public form: IProjectDetailsForm = {
    valid: false,
    fields: {
      project_name: '',
      project_type: '',
      project_desc: '',
      name: '',
      description: '',
      share_description: false,
      date_from: '',
      date_to: '',
      location: '',
      dateTba: false,
      locationTba: false,
      city: '',
    },
    rules: {
      project_name: [FormRules.required('Project Name'), FormRules.minLength('Project Name', 5)],
      project_type: [FormRules.required('Project Type')],
      project_desc: [
        FormRules.required('Project Description'),
        FormRules.minLength('project Description', 5),
      ],
      name: [FormRules.required('Role Name'), FormRules.minLength('Role Name', 5)],
      description: [
        FormRules.required('Role Description'),
        FormRules.minLength('Role Description', 5),
      ],
      date_from: [FormRules.required('Date')],
      date_to: [FormRules.required('Date')],
      city: [FormRules.required('Location')],
    },
  };

  public created() {
    this.form.fields = updateForm(this.form.fields, this.roleDetails) as any;
    this.form.fields.date_from = moment(this.roleDetails.date_from || Date.now())
      .toISOString()
      .substr(0, 10);
    this.form.fields.date_to = moment(this.roleDetails.date_to || Date.now())
      .toISOString()
      .substr(0, 10);
  }

  public getAddressData(data: any) {
    this.form.fields.city = data.name;
    this.form.fields.location = {
      type: 'Point',
      coordinates: [data.latitude, data.longitude],
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.updateDetails({
        objectId: this.$route.params.id,
        data: this.form.fields,
      });
    }
  }
}
