import { GetterTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { IActorConvState } from '@/modules/actor/connections/store/state';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export const getters: GetterTree<IActorConvState, IRootState> = {
  isLoading: (state: IActorConvState): boolean => {
    return state.isLoading;
  },
  roles: (state: IActorConvState): IConversation[] => {
    return state.roles;
  },
  getRoleById: (state: IActorConvState, id: string): IConversation => {
    return state.roles.find((role) => role.objectId === id);
  },
};
