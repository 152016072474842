import { IPlainObject } from '@/_shared/models/plain-object.model';

export function updateForm<T = any>(form: IPlainObject, data: T) {
  const merged = { ...form };

  for (const key in data) {
    if (data.hasOwnProperty(key) && merged.hasOwnProperty(key)) {
      merged[key] = data[key];
    }
  }

  return merged;
}
