export const USER_GENDER = ['Male', 'Female', 'Other'];
export const ACTOR_STEPS = [1, 2, 3, 4, 5, 6, 7];

export const StepsLabels = {
  1: 'Personal Data',
  2: 'Location',
  3: 'Visual Appearance',
  4: 'Experience',
  5: 'Skills',
  6: 'Portfolio',
  7: 'Portfolio',
};
