















import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { ISkill, IUser } from '@/_core/models/user.model';
import { AuthActionsTypes } from '@/_core/store/auth/types';
import ClTagsLarge from '@/_shared/components/cl-tags-large/cl-tags-large.vue';

@Component({
  name: 'actor-skills',
  components: { ClTagsLarge },
})
export default class ActorSkills extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
  @APP_NAMESPACES.auth.Getter('updateLoading') updateLoading!: boolean;

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.UPDATE_USER) updateUser: (
    data: Partial<IUser>
  ) => void;

  private savedSkills: ISkill[] = [];

  public mounted() {
    this.savedSkills = this.user.skills;
  }

  public onChanged(skills: ISkill[]) {
    this.savedSkills = skills;
  }

  public onSave() {
    this.updateUser({ skills: this.savedSkills });
  }
}
