export const AUTH_NAMESPACE = 'auth';

export enum AuthActionsTypes {
  LOGIN = '[AUTH] LOGIN',
  SOCIAL_LOGIN = '[AUTH] SOCIAL_LOGIN',
  CREATE_USER = '[AUTH] CREATE_USER',
  UPDATE_USER = '[AUTH] UPDATE_USER',
  GET_USER = '[AUTH] GET_USER',
  LOGOUT = '[AUTH] LOGOUT',
}

export enum AuthMutationTypes {
  SET_LOADING = '[AUTH] SET_LOADING',
  LOGIN_FAILED = '[AUTH] LOGIN_FAILED',
  SET_USER = '[AUTH] SET_USER',
  UPDATE_USER_LOADING = '[AUTH] UPDATE_USER_LOADING',
  UPDATE_USER_FAILED = '[AUTH] UPDATE_USER_FAILED',
  SET_INITIAL_LOADING = '[AUTH] SET_INITIAL_LOADING',
  SET_ROLE_SETTINGS = '[AUTH] SET_ROLE_SETTINGS',
  SET_EMAIL_CONFIRM = '[AUTH] SET_EMAIL_CONFIRM',
  RESET = '[AUTH] RESET',
}
