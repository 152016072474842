import { MutationTree } from 'vuex';
import { IDetailsState } from '@/_core/store/details/state';
import { DetailsMutationTypes } from '@/_core/store/details/types';

export const mutations: MutationTree<IDetailsState> = {
  [DetailsMutationTypes.SET_LOADING](state: IDetailsState, payload: boolean) {
    state.isLoading = payload;
  },
  [DetailsMutationTypes.SET_STEP](state: IDetailsState, payload: number) {
    state.currentStep = payload;
  },
};
