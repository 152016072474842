import { IUser } from '@/_core/models/user.model';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';

export interface IAuthState {
  user: IUser;
  isLoading: boolean;
  updateLoading: boolean;
  initialLoading: boolean;
  roleSettings: IActorRoleSetting[];
  emailConfirm: boolean;
}

export const initialState: IAuthState = {
  user: null,
  isLoading: false,
  updateLoading: false,
  initialLoading: true,
  roleSettings: [],
  emailConfirm: false
};
