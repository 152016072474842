import axios from 'axios';
import { APP_CONFIG } from '@/_core/constants/app.config';

const http = axios.create();

http.interceptors.request.use((config) => {
  return {
    ...config,
    url: `https://api.backendless.com/${APP_CONFIG.backendless.appId}/${APP_CONFIG.backendless.apiKey}${config.url}`,
  };
});

http.interceptors.response.use((response) => {
  return response.data;
});

export default http;
