












import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'cl-snackbar',
})
export default class ClSnackbar extends Vue {
  public show = false;
  public color = '';
  public text = 'Please upload at least 1 photo';
  public timeout = 0;
  public snackbar = false;
}
