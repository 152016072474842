





































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import ClReminder from '@/_shared/components/cl-reminder/cl-reminder.vue';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { IPlainObject } from '@/_shared/models/plain-object.model';
import { VForm } from '@/_shared/types/v-form';
import ClTags from '@/_shared/components/cl-tags/cl-tags.vue';
import { ITag } from '@/_shared/models/tag.model';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { CastingsSearchActionsTypes } from '@/_core/store/casting-search/types';

@Component({ name: 'create-search', components: { ClReminder, ClTags } })
export default class CreateSearch extends Vue {
  @APP_NAMESPACES.castingSearch.Action(CastingsSearchActionsTypes.CREATE) createSearch!: (
    payload: unknown
  ) => void;

  @APP_NAMESPACES.castingSearch.Getter('isCreateLoading') isLoading!: boolean;

  @Ref('formRef') readonly formRef!: VForm;

  @Watch('$route.params.id', { immediate: false, deep: false })
  private onRouteChanged() {
    this.roleId = this.$route.params.id;
  }

  private locationData!: IPlainObject;
  public roleId = this.$route.params.id;
  public form = {
    valid: false,
    fields: {
      name: '',
      fullLocation: '',
      location: '',
      actorType: 'eligible',
      tags: [] as ITag[],
    },
    rules: {
      name: [FormRules.required('Name')],
      location: [FormRules.required('Location')],
    },
  };

  public getAddressData(data: IPlainObject) {
    this.form.fields.location = data.name;
    this.locationData = data;
  }

  public onTagChanged(tags: ITag[]) {
    this.form.fields.tags = tags;
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.createSearch({ roleId: this.roleId, form: this.form.fields });
    }
  }
}
