















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import SearchIcon from '@/assets/icons/search.svg';
import { debounce } from 'lodash';
import backendless from 'backendless';
import { ITag } from '@/_shared/models/tag.model';

@Component({ name: 'cl-tags', components: { SearchIcon } })
export default class ClTags extends Vue {
  @Prop() readonly predefinedTags!: ITag[];

  @Emit('onChange') onChange() {
    return this.selectedTags;
  }

  public dropdownVisible = false;
  public tags: ITag[] = [];
  public selectedTags: ITag[] = [];

  public onCloseDropdown() {
    this.dropdownVisible = false;
  }

  public getRandomColor(): string {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  public onSearch(e: any) {
    const text = e.target.value;

    if (!text || !text.trim().length) {
      this.dropdownVisible = false;
      return;
    }

    backendless.Data.of('tags')
      .find<ITag>({ where: `name LIKE '%${text}%'` })
      .then((tags: ITag[]) => {
        this.tags = tags.filter(
          (tag) => this.selectedTags.findIndex((sTag) => sTag.objectId === tag.objectId) === -1
        );
        this.dropdownVisible = true;
      });
  }

  public onSelectTag(tag: ITag) {
    this.selectedTags = [...this.selectedTags, tag];
    this.dropdownVisible = false;
    this.onChange();
  }

  public onRemoveTag(tag: ITag) {
    this.selectedTags = this.selectedTags.filter(({ objectId }) => tag.objectId !== objectId);
    this.onChange();
  }

  public created() {
    this.onSearch = debounce(this.onSearch, 200);
    this.selectedTags = this.predefinedTags || [];
  }
}
