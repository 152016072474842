import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export interface IActorConvState {
  roles: IConversation[];
  isLoading: boolean;
}

export const initialState: IActorConvState = {
  roles: [],
  isLoading: false,
};
