import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { APP_CONFIG } from '@/_core/constants/app.config';
import Login from '@/modules/login/login.vue';
import Dashboard from '@/modules/director/dashboard/dashboard.vue';
import Ranking from '@/modules/director/ranking/ranking.vue';
import Details from '@/modules/details/details.vue';
import CastingCalls from '@/modules/director/casting-calls/casting-calls.vue';
import CastingCallsDiscussions from '@/modules/director/casting-calls/containers/casting-calls-discussions/casting-calls-discussions.vue';
import CastingCallsSearch from '@/modules/director/casting-calls/containers/casting-calls-search/casting-calls-search.vue';
import CastingCallsAdd from '@/modules/director/casting-calls/containers/casting-calls-add/casting-calls-add.vue';
import Vuex from 'vuex';
import CastingCallsMessages from '@/modules/director/casting-calls/containers/casting-calls-messages/casting-calls-messages.vue';
import CastingCallsProject from '@/modules/director/casting-calls/containers/casting-calls-project/casting-calls-project.vue';
import CastingCallsDetail from '@/modules/director/casting-calls/containers/casting-calls-detail/casting-calls-detail.vue';
import CastingCallsHistory from '@/modules/director/casting-calls/containers/casting-calls-history/casting-calls-history.vue';
// @ts-ignore
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import DirectorAccount from '@/modules/director/director-account/director-account.vue';
import DirectorDetails from '@/modules/director/director-account/containers/director-details/director-details.vue';
import DirectorPassword from '@/modules/director/director-account/containers/director-password/director-password.vue';
import DirectorPayment from '@/modules/director/director-account/containers/director-payment/director-payment.vue';
import DirectorNotifications from '@/modules/director/director-account/containers/director-notifications/director-notifications.vue';
import CreateSearch from '@/modules/director/create-search/create-search.vue';
import { UserRole } from '@/_core/constants/user-role.const';
import Resume from '@/modules/actor/resume/resume.vue';
import ActorOverview from '@/modules/actor/resume/containers/actor-overview/actor-overview.vue';
import ActorInformation from '@/modules/actor/resume/containers/actor-information/actor-information.vue';
import ActorExperience from '@/modules/actor/resume/containers/actor-experience/actor-experience.vue';
import ActorSkills from '@/modules/actor/resume/containers/actor-skills/actor-skills.vue';
import ActorPhotos from '@/modules/actor/resume/containers/actor-photos/actor-photos.vue';
import ActorShowreels from '@/modules/actor/resume/containers/actor-showreels/actor-showreels.vue';
import ActorDesiredProjects from '@/modules/actor/resume/containers/actor-desired-projects/actor-desired-projects.vue';
import ActorRestrictions from '@/modules/actor/resume/containers/actor-restrictions/actor-restrictions.vue';
import Connections from '@/modules/actor/connections/connections.vue';
import ConnectionDetails from '@/modules/actor/connections/containers/connection-details.vue';

const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: '/dashboard',
    meta: {
      auth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      finishedInfo: true,
    },
  },
  {
    path: '/casting-calls',
    name: 'Casting Calls',
    component: CastingCalls,
    meta: {
      auth: true,
      roles: [UserRole.DIRECTOR],
      finishedInfo: true,
    },
    children: [
      { path: '', redirect: 'discussions' },
      {
        path: 'discussions',
        component: CastingCallsDiscussions,
        meta: {
          auth: true,
          roles: [UserRole.DIRECTOR],
          finishedInfo: true,
        },
        children: [
          {
            path: ':id',
            component: CastingCallsDetail,
            redirect: ':id/messages',
            children: [
              { path: 'messages', component: CastingCallsMessages },
              { path: 'project', component: CastingCallsProject },
              { path: 'search', component: CastingCallsHistory },
            ],
          },
        ],
      },
      {
        path: 'add',
        component: CastingCallsAdd,
        meta: {
          auth: true,
          roles: [UserRole.DIRECTOR],
          finishedInfo: true,
        },
      },
      {
        path: 'search/:roleId/:id',
        component: CastingCallsSearch,
        meta: {
          auth: true,
          roles: [UserRole.DIRECTOR],
          finishedInfo: true,
        },
      },
    ],
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    meta: {
      auth: true,
      finishedInfo: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false,
    },
  },
  {
    path: '/details',
    name: 'Details',
    component: Details,
    meta: {
      auth: true,
      finishedInfo: false,
    },
  },
  {
    path: '/create-search/:id',
    name: 'Create new Search',
    component: CreateSearch,
  },
  {
    path: '/director-account',
    name: 'Director Account',
    component: DirectorAccount,
    children: [
      { path: '', redirect: 'details' },
      { path: 'details', component: DirectorDetails },
      { path: 'update-password', component: DirectorPassword },
      { path: 'payments', component: DirectorPayment },
      { path: 'notifications', component: DirectorNotifications },
    ],
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume,
    meta: {
      auth: true,
      finishedInfo: true,
      roles: [UserRole.ACTOR],
    },
    children: [
      { path: '', redirect: 'overview' },
      { path: 'overview', component: ActorOverview },
      { path: 'information', component: ActorInformation },
      { path: 'experience', component: ActorExperience },
      { path: 'skills', component: ActorSkills },
      { path: 'photos', component: ActorPhotos },
      { path: 'showreels', component: ActorShowreels },
      { path: 'desired-projects', component: ActorDesiredProjects },
      { path: 'restrictions', component: ActorRestrictions },
    ],
  },
  {
    path: '/connections',
    name: 'Connections',
    component: Connections,
    meta: {
      auth: true,
      finishedInfo: true,
      roles: [UserRole.ACTOR],
    },
    children: [
      {
        path: ':id',
        component: ConnectionDetails,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (!user && to.name !== 'Login') {
    localStorage.clear();
    return next('login');
  }

  if (to.name === 'Login' && user && user.finishedInfo) {
    return next('/dashboard');
  }

  if (!user && to.meta.auth) {
    return next('/login');
  }

  if (user && to.name === 'Login') {
    return next('/dashboard');
  }

  if (user && !user.finishedInfo && to.meta.finishedInfo) {
    return next('/details');
  }

  if (user && user.finishedInfo && to.name === 'Details') {
    return next('/dashboard');
  }

  return next();
});

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: APP_CONFIG.google.apiKey,
  language: 'EN',
});

export default router;
