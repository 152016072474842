













































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'director-notifications' })
export default class DirectorNotifications extends Vue {
  public switcher = false;
}
