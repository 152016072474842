





















import { Component, Vue } from 'vue-property-decorator';
import ClTab from '@/_shared/components/cl-tabs/cl-tab.vue';
import ClTabs from '@/_shared/components/cl-tabs/cl-tabs.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';
import ActorBioForm from '@/modules/actor/resume/components/actor-bio-form/actor-bio-form.vue';
import ActorBioAppearance from '@/modules/actor/resume/components/actor-bio-appearance/actor-bio-appearance.vue';
import { AuthActionsTypes } from '@/_core/store/auth/types';

@Component({
  name: 'actor-information',
  components: { ClTabs, ClTab, ActorBioForm, ActorBioAppearance },
})
export default class ActorInformation extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
  @APP_NAMESPACES.auth.Getter('updateLoading') updateLoading!: boolean;

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.UPDATE_USER) updateUser: (
    data: Partial<IUser>
  ) => void;
}
