



























import { Vue, Component } from 'vue-property-decorator';
import CardInfo from '@/modules/director/director-account/components/card-info/card-info.vue';

@Component({ name: 'director-payment', components: { CardInfo } })
export default class DirectorPayment extends Vue {}
