









































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { IFormDefinition } from '@/_core/models/form-definition.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { VForm } from '@/_shared/types/v-form';

@Component({ name: 'forgot-password' })
export default class ForgotPassword extends Vue {
  @Prop(Boolean) isOpened!: boolean;
  @Ref('formRef') readonly formRef!: VForm;
  @Emit('onClose') onClose() {}

  public form: IFormDefinition = {
    valid: false,
    fields: {
      email: '',
    },
    rules: {
      email: [FormRules.required('Email'), FormRules.validEmail('Email')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onClose();
    }
  }
}
