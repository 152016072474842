export const DETAILS_NAMESPACE = 'details';

export enum DetailsActionsTypes {
  SETUP_ACTOR = '[DETAILS] SETUP_ACTOR',
  SETUP_DIRECTOR = '[DETAILS] SETUP_DIRECTOR',
}

export enum DetailsMutationTypes {
  SET_LOADING = '[DETAILS] SET_LOADING',
  SET_STEP = '[DETAILS] SET_STEP',
}
