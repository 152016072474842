import { GetterTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingSearchState } from '@/_core/store/casting-search/state';
import { ICastingSearch } from '@/_shared/models/casting-search.model';
import { IActor } from '@/_shared/models/actor.model';

export const getters: GetterTree<ICastingSearchState, IRootState> = {
  isCreateLoading: (state: ICastingSearchState): boolean => {
    return state.isCreateLoading;
  },
  isLoading: (state: ICastingSearchState): boolean => {
    return state.isLoading;
  },
  isConversationsLoading: (state: ICastingSearchState): boolean => {
    return state.isConversationsLoading;
  },
  search: (state: ICastingSearchState): ICastingSearch => {
    return state.search;
  },
  actors: (state: ICastingSearchState): IActor[] => {
    return state.actors;
  },
  selectedActors: (state: ICastingSearchState): IActor[] => {
    return state.selectedActors;
  },
  findLoading: (state: ICastingSearchState): boolean => {
    return state.isFindLoading;
  },
};
