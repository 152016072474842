import { MutationTree } from 'vuex';
import { IActorConvState } from '@/modules/actor/connections/store/state';
import { ActorConvMutationTypes } from '@/modules/actor/connections/store/types';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';

export const mutations: MutationTree<IActorConvState> = {
  [ActorConvMutationTypes.SET_LOADING](state: IActorConvState, payload: boolean) {
    state.isLoading = payload;
  },
  [ActorConvMutationTypes.SET_CONVERSATIONS](state: IActorConvState, payload: IConversation[]) {
    state.roles = payload;
  },
  [ActorConvMutationTypes.ADD_MESSAGE](state: IActorConvState, payload: any) {
    state.roles = state.roles.map((conv) => {
      return conv.objectId !== payload.conversationId
        ? conv
        : {
            ...conv,
            messages: [...conv.messages, payload.message],
          };
    });
  },
};
