export const CASTING_SEARCH_NAMESPACE = 'castingSearch';

export enum CastingsSearchActionsTypes {
  CREATE = '[CASTING SEARCH] CREATE',
  FETCH = '[CASTING SEARCH] FETCH',
  FIND_ACTORS = '[CASTING SEARCH] FIND_ACTORS',
  CREATE_MESSAGES = '[CASTING SEARCH] CREATE_MESSAGES',
}

export enum CastingsSearchMutationTypes {
  SET_LOADING = '[CASTING SEARCH] SET_LOADING',
  SET_CREATE_LOADING = '[CASTING SEARCH] SET_CREATE_LOADING',
  SET_SEARCH = '[CASTING SEARCH] SET_SEARCH',
  SET_ACTORS = '[CASTING SEARCH] SET_ACTORS',
  ADD_ACTOR = '[CASTING SEARCH] ADD_ACTOR',
  REMOVE_ACTOR = '[CASTING SEARCH] REMOVE_ACTOR',
  SET_FIND_LOADING = '[CASTING SEARCH] SET_FIND_LOADING',
  SET_CONVERSATIONS_LOADING = '[CASTING SEARCH] SET_CONVERSATIONS_LOADING',
  RESET = '[CASTING SEARCH] RESET',
}
