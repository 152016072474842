




























































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { IDetailsForm } from '@/modules/details/models/details-form.model';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { DetailsActionsTypes } from '@/_core/store/details/types';

@Component({ name: 'details-form' })
export default class DetailsForm extends Vue {
  @APP_NAMESPACES.details.Action(DetailsActionsTypes.SETUP_DIRECTOR) setupDirector: (
    data: Partial<IUser>
  ) => void;

  @APP_NAMESPACES.details.Getter('isLoading') isLoading!: boolean;

  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  public form: IDetailsForm = {
    valid: false,
    fields: {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      companyName: this.user.companyName,
      website: this.user.website,
      country: 'USA',
    },
    rules: {
      firstName: [FormRules.required('First Name')],
      lastName: [FormRules.required('Last Name')],
      companyName: [FormRules.required('Company Name')],
      website: [FormRules.required('Website'), FormRules.validWebsite('Website')],
      country: [FormRules.required('Country')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.setupDirector(this.form.fields);
    }
  }
}
