






import { Component, Vue } from 'vue-property-decorator';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';
import ClActorCv from '@/_shared/components/cl-actor-cv/cl-actor-cv.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';

@Component({
  name: 'actor-overview',
  components: { ClSpinner, ClActorCv },
})
export default class ActorOverview extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;
}
