import { ActionTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { DetailsActionsTypes, DetailsMutationTypes } from '@/_core/store/details/types';
import { IDetailsState } from '@/_core/store/details/state';
import backendless from 'backendless';
import { IMediaPhoto } from '@/_core/models/media_photo.model';
import router from '@/app-routes';
import { AUTH_NAMESPACE, AuthMutationTypes } from '@/_core/store/auth/types';
import { handleApiError } from '@/_shared/utils/error-handler.util';
import { IUser } from '@/_core/models/user.model';

export const actions: ActionTree<IDetailsState, IRootState> = {
  async [DetailsActionsTypes.SETUP_ACTOR]({ commit, rootState }, payload) {
    commit(DetailsMutationTypes.SET_LOADING, true);

    try {
      const actor = await backendless.Data.of('actors').save({ ...payload, finishedInfo: true });
      const PARENT_OBJECT = { objectId: actor.objectId };
      const mainPhoto: IMediaPhoto = await backendless.Data.of('media_photos').save<IMediaPhoto>({
        uri: payload.mainPhoto,
        primary: true,
      });

      const portfolioPromises = payload.portfolio.map((uri: string) =>
        backendless.Data.of('media_photos').save<IMediaPhoto>({
          uri,
          primary: false,
        })
      );

      const media: IMediaPhoto[] = await Promise.all(portfolioPromises);

      await backendless.Data.of('actors').setRelation(PARENT_OBJECT, 'mainPhoto', [
        mainPhoto.objectId,
      ]);
      await backendless.Data.of('actors').setRelation(
        PARENT_OBJECT,
        'mediaPhotos',
        media.map((item) => item.objectId)
      );

      await backendless.Data.of('actors').setRelation(
        PARENT_OBJECT,
        'skills',
        payload.skills.map((skill: any) => skill.objectId)
      );
      const user: IUser = await backendless.CustomServices.invoke('UserInfo', 'my', {});
      commit(`${AUTH_NAMESPACE}/${AuthMutationTypes.SET_USER}`, user, { root: true });
      router.push('/dashboard');
      commit(DetailsMutationTypes.SET_LOADING, false);
    } catch (e) {
      handleApiError(e);
      commit(DetailsMutationTypes.SET_LOADING, false);
    }
  },
  async [DetailsActionsTypes.SETUP_DIRECTOR]({ commit, rootState }, payload) {
    commit(DetailsMutationTypes.SET_LOADING, true);

    try {
      const director = await backendless.Data.of('casting_directors').save({
        ...payload,
        finishedInfo: true,
      });
      commit(DetailsMutationTypes.SET_LOADING, false);
      commit(
        `${AUTH_NAMESPACE}/${AuthMutationTypes.SET_USER}`,
        {
          ...rootState.auth.user,
          ...director,
        },
        { root: true }
      );
      router.push('/dashboard');
    } catch (e) {
      handleApiError(e);
      commit(DetailsMutationTypes.SET_LOADING, false);
    }
  },
};
