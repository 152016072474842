























import { Component, Prop, Vue } from 'vue-property-decorator';
import CameraIcon from '@/assets/icons/camera.svg';
import PlusCircleIcon from '@/assets/icons/plus-circle.svg';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';

@Component({
  name: 'casting-aside',
  components: { CameraIcon, PlusCircleIcon },
})
export default class CastingAside extends Vue {
  @Prop() readonly calls!: ICastingRole[];
}
