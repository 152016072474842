


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { IUser } from '@/_core/models/user.model';
import ClActorCv from '@/_shared/components/cl-actor-cv/cl-actor-cv.vue';

@Component({ components: { StepperFooter, ClActorCv } })
export default class Resume extends Vue {
  @Prop() readonly user: IUser;
  @Prop() readonly info: Partial<IUser>;
  @Prop() readonly isLoading: boolean;

  @Emit('onFinishProfile') onFinishProfile() {}

  @Emit('onBack') onBack() {}
}
