























import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { IUser } from '@/_core/models/user.model';
import { APP_CONFIG } from '@/_core/constants/app.config';
import ClPhotoGallery from '@/_shared/components/cl-photo-gallery/cl-photo-gallery.vue';

@Component({
  name: 'actor-photos',
  components: {
    ClPhotoGallery,
  },
})
export default class ActorPhotos extends Vue {
  @APP_NAMESPACES.auth.Getter('user') user!: IUser;

  public selectedImage = 0;
  public galleryOpened = false;
  public mediaBase = APP_CONFIG.media.baseUrl;

  public openGallery(index: number) {
    this.selectedImage = index;
    this.galleryOpened = true;
  }

  public hidePhotoSwipe() {
    this.galleryOpened = false;
  }
}
