import { ActionTree } from 'vuex';
import { IAuthState } from '@/_core/store/auth/state';
import { AuthActionsTypes, AuthMutationTypes } from '@/_core/store/auth/types';
import { IRootState } from '@/_core/models/root-state.model';
import backendless from 'backendless';
import router from '@/app-routes';
import { IUser } from '@/_core/models/user.model';
import { Social } from '@/_core/constants/social.const';
import { handleApiError } from '@/_shared/utils/error-handler.util';
import { IActorRoleSetting } from '@/_core/models/actor-role-setting.model';
import analytic from '@/_core/services/analytic.service';

export const actions: ActionTree<IAuthState, IRootState> = {
  async [AuthActionsTypes.GET_USER]({ commit, rootState }) {
    const token = !!localStorage.getItem('user');

    if (!token) {
      commit(AuthMutationTypes.SET_INITIAL_LOADING, false);
      return;
    }

    try {
      const user: IUser = await backendless.CustomServices.invoke('UserInfo', 'my', {});

      if (!rootState.auth.roleSettings.length) {
        const roleSettings: IActorRoleSetting[] = await backendless.Data.of(
          'dict_role_type'
        ).find();
        commit(AuthMutationTypes.SET_ROLE_SETTINGS, roleSettings);
      }
      analytic.init(user.objectId);
      commit(AuthMutationTypes.SET_USER, user);
      commit(AuthMutationTypes.SET_INITIAL_LOADING, false);
    } catch (e) {
      handleApiError(e);
    }
  },
  async [AuthActionsTypes.CREATE_USER]({ commit, dispatch }, payload) {
    commit(AuthMutationTypes.SET_LOADING, true);
    let user: Partial<IUser> = new backendless.User();
    user = { ...user, ...payload };

    try {
      await backendless.UserService.register(user);
      await backendless.UserService.login(payload.email, payload.password, true);
      await backendless.CustomServices.invoke('UserInfo', 'setRole', payload.role);
    } catch (e) {
      commit(AuthMutationTypes.LOGIN_FAILED);
      handleApiError(e);
      return;
    }

    dispatch(AuthActionsTypes.LOGIN, {
      email: user.email,
      password: user.password,
    });
  },
  async [AuthActionsTypes.LOGIN]({ commit }, payload) {
    commit(AuthMutationTypes.SET_LOADING, true);

    try {
      const user = await backendless.UserService.login(payload.email, payload.password, true);
      const userData: IUser = await backendless.CustomServices.invoke('UserInfo', 'my', {});
      const data = { ...user, ...userData };

      localStorage.setItem('user', JSON.stringify(data));
      analytic.init(data.objectId);

      commit(AuthMutationTypes.SET_LOADING, false);
      commit(AuthMutationTypes.SET_USER, data);
      router.push('/details');
    } catch (e) {
      if (e && e.code === 3087) {
        commit(AuthMutationTypes.SET_EMAIL_CONFIRM, true);
      }

      commit(AuthMutationTypes.LOGIN_FAILED);
      handleApiError(e);
    }
  },
  async [AuthActionsTypes.SOCIAL_LOGIN](
    { commit },
    payload: { data: Partial<IUser>; social: Social }
  ) {
    commit(AuthMutationTypes.SET_LOADING, true);

    // @ts-ignore
    const user: IUser =
      payload.social === Social.GOOGLE
        ? await backendless.UserService.loginWithGooglePlus(
            {
              email: 'email',
              given_name: 'firstname',
              family_name: 'lastname',
            },
            null,
            null,
            true
          )
        : await backendless.UserService.loginWithFacebook(null, null, true);

    if (!user.role) {
      try {
        await backendless.CustomServices.invoke('UserInfo', 'setRole', payload.data.role);
        const my = await backendless.CustomServices.invoke('UserInfo', 'my', {});
        let update = { ...my, finishedInfo: payload.data.finishedInfo };

        analytic.init(update.objectId);

        if (payload.social === Social.FACEBOOK) {
          update = {
            ...update,
            firstName: (update.name || ' ').split(' ')[0],
            lastName: (update.name || ' ').split(' ')[1],
          };
        }

        const updatedUser = await backendless.UserService.update(update);
        commit(AuthMutationTypes.SET_LOADING, false);
        commit(AuthMutationTypes.SET_USER, updatedUser);
        router.push('/details');
        return;
      } catch (e) {
        handleApiError(e);
      }
    }

    const userData: IUser = await backendless.CustomServices.invoke('UserInfo', 'my', {});

    analytic.init(userData.objectId);

    commit(AuthMutationTypes.SET_LOADING, false);
    commit(AuthMutationTypes.SET_USER, { ...user, ...userData });
    localStorage.setItem('user', JSON.stringify({ ...user, ...userData }));
    router.push('/dashboard');
  },
  async [AuthActionsTypes.UPDATE_USER]({ commit, dispatch }, payload: Partial<IUser>) {
    commit(AuthMutationTypes.UPDATE_USER_LOADING, true);

    try {
      await backendless.CustomServices.invoke('UserInfo', 'updateUser', payload);
      commit(AuthMutationTypes.UPDATE_USER_LOADING, false);
    } catch (error) {
      commit(AuthMutationTypes.UPDATE_USER_LOADING, false);
      handleApiError(error);
    }

    dispatch(AuthActionsTypes.GET_USER);
  },
  async [AuthActionsTypes.LOGOUT]({ commit }) {
    await backendless.UserService.logout();
    localStorage.clear();

    commit(AuthMutationTypes.RESET);
    router.push('/login');
  },
};
