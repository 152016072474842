




























































import { Component, Ref, Vue } from 'vue-property-decorator';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { VForm } from '@/_shared/types/v-form';
import ForgotPassword from '@/modules/director/director-account/components/forgot-password/forgot-password.vue';

@Component({ name: 'director-password', components: { ForgotPassword } })
export default class DirectorPassword extends Vue {
  @Ref('formRef') readonly formRef!: VForm;

  public forgotDialog = false;
  public form: any = {
    valid: false,
    fields: {},
    rules: {
      current: [FormRules.required('Current password')],
      new: [FormRules.required('New password')],
      confirm: [FormRules.required('Confirm password')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      console.log(this.form.fields);
    }
  }
}
