import { render, staticRenderFns } from "./personal-data.vue?vue&type=template&id=e5b1eb90&scoped=true&"
import script from "./personal-data.vue?vue&type=script&lang=ts&"
export * from "./personal-data.vue?vue&type=script&lang=ts&"
import style0 from "./personal-data.vue?vue&type=style&index=0&id=e5b1eb90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5b1eb90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VForm,VRow,VSelect,VTextField})
