export const ACTOR_CONVERSATIONS_NAMESPACE = 'actorConv';

export enum ActorConvActionsTypes {
  FETCH = '[ACTOR_CONVERSATIONS] FETCH',
  SEND_MESSAGE = '[ACTOR_CONVERSATIONS] ADD_MESSAGE',
}

export enum ActorConvMutationTypes {
  SET_LOADING = '[ACTOR_CONVERSATIONS] SET_LOADING',
  SET_CONVERSATIONS = '[ACTOR_CONVERSATIONS] SET_CONVERSATIONS',
  ADD_MESSAGE = '[ACTOR_CONVERSATIONS] ADD_MESSAGE',
  RESET = '[ACTOR_CONVERSATIONS] RESET',
}
