


































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { VForm } from '@/_shared/types/v-form';

@Component({ name: 'share-shortlist' })
export default class ShareShortlist extends Vue {
  @Prop(Boolean) isOpened!: boolean;

  @Emit('onClose') onClose() {}

  @Ref('formRef') readonly formRef!: VForm;

  public get opened(): boolean {
    return this.isOpened;
  }

  public form = {
    valid: false,
    fields: {
      email: '',
    },
    rules: {
      email: [FormRules.required('Email'), FormRules.validEmail('Email')],
    },
  };

  public onFormSubmit() {
    if (this.form.fields.email) {
      this.onClose();
    }
  }
}
