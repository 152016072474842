


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';
import moment from 'moment';

@Component({
  name: 'connection-list',
  filters: {
    updatedAt(time: number): string {
      return moment(time).fromNow();
    },
  },
})
export default class ConnectionList extends Vue {
  @Prop() list!: IConversation[];

  public initials(conv: IConversation) {
    const { firstName, lastName } = conv.casting_director;
    return firstName[0] + lastName[0];
  }

  public getLastMessage(conv: IConversation) {
    return conv.messages[conv.messages.length - 1];
  }
}
