


































































import { Component, Vue } from 'vue-property-decorator';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';
import analytic from '@/_core/services/analytic.service';

@Component({
  name: 'resume',
  components: { ClSpinner },
})
export default class Resume extends Vue {
  public mounted() {
    analytic.track(`Navigation: Resume`);
  }
}
