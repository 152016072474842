



















import { Component, Prop, Vue } from 'vue-property-decorator';
import CameraIcon from '@/assets/icons/camera.svg';

@Component({
  name: 'cl-empty-state',
  components: { CameraIcon },
})
export default class ClEmptyState extends Vue {
  @Prop() title: string;
  @Prop() description: string;
  @Prop() actions: boolean;
}
