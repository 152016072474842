import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { Module } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { ICastingSearchState, initialState } from '@/_core/store/casting-search/state';

export const castingSearchStoreModule: Module<ICastingSearchState, IRootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
