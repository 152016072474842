





















































































import { Component, Vue } from 'vue-property-decorator';
import SearchMaskIcon from '@/assets/icons/search_mask.svg';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import moment from 'moment';

@Component({
  name: 'casting-calls-history',
  components: { SearchMaskIcon },
  filters: {
    updatedAt(time: number): string {
      return moment(time).format('ddd, hA');
    },
  },
})
export default class CastingCallsHistory extends Vue {
  @APP_NAMESPACES.castings.Getter('roleDetails') roleDetails!: ICastingRole;
}
