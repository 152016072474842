
























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import LogoIcon from '@/assets/icons/logo.svg';
import { IUser } from '@/_core/models/user.model';
import { APP_CONFIG } from '@/_core/constants/app.config';
import { UserRole } from '@/_core/constants/user-role.const';

@Component({
  name: 'cl-header',
  components: { LogoIcon },
})
export default class CLHeader extends Vue {
  @Prop(Boolean) finishedInfo!: boolean;
  @Prop() user!: IUser;

  public get isActor(): boolean {
    return this.user.role === UserRole.ACTOR;
  }

  public mediaBase = APP_CONFIG.media.baseUrl;

  @Emit('logout') logout() {}
}
