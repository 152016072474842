

















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ClTags from '@/_shared/components/cl-tags/cl-tags.vue';
import { ITag } from '@/_shared/models/tag.model';
import { ICreateSearch } from '@/modules/director/create-search/models/create-search.model';

@Component({ name: 'casting-search-aside', components: { ClTags } })
export default class CastingSearchAside extends Vue {
  @Prop() formData!: any;

  @Emit('formChanged') formChanged() {
    return { ...this.form, location: this.form.location.split(',')[0] };
  }

  public cities!: string[];
  public form!: Partial<ICreateSearch>;

  public onTagsChanged(tags: ITag[]) {
    this.form = { ...this.form, tags };
    this.formChanged();
  }

  created() {
    this.form = { ...this.formData };
  }
}
