




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'stepper-footer',
})
export default class StepperFooter extends Vue {
  @Prop(Boolean) hideBack!: boolean;
  @Prop(Boolean) confirmLoading!: boolean;
  @Prop(String) confirmText!: string;

  @Emit('onBack') onBack() {}
}
