import { GetterTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { IDetailsState } from '@/_core/store/details/state';

export const getters: GetterTree<IDetailsState, IRootState> = {
  currentStep: (state: IDetailsState): number => {
    return state.currentStep;
  },
  isLoading: (state: IDetailsState): boolean => {
    return state.isLoading;
  },
};
