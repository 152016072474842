






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { uploadPhoto } from '@/_shared/utils/upload-photo.util';

@Component({
  name: 'connection-footer',
})
export default class ConnectionFooter extends Vue {
  @Prop() convId: string;

  @Emit() sendMessage(message?: string, type = 'message') {
    return {
      message: message || this.message,
      type,
      convId: this.convId,
    };
  }

  public message = '';

  public onSendMessage() {
    this.sendMessage();
    this.message = '';
  }

  public async uploadFileMessage(evt: any) {
    const file = evt.target.files[0];
    this.sendMessage(await uploadPhoto(file), 'image');
  }
}
