import { IRole } from '@/modules/details/models/actor-stepper-form.model';

export const ROLE_INITIAL: IRole = {
  type: '',
  name: '',
  year: '',
  role: '',
  studio: '',
  director: '',
};
