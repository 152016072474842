


























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import backendless from 'backendless';
import { APP_CONFIG } from '@/_core/constants/app.config';
import DeleteIcon from '@/assets/icons/delete-image.svg';
import ActorPhotoBg from '@/assets/icons/actor-photo-bg.svg';

import axios from 'axios';

@Component({ name: 'cl-upload', components: { DeleteIcon, ActorPhotoBg } })
export default class CLUpload extends Vue {
  @Prop(String) ownerId!: string;

  @Emit('addPhoto') onAddPhoto() {
    return this.previewUrl;
  }

  @Emit('removePhoto') onRemovePhoto() {
    return '';
  }

  public mediaBase = APP_CONFIG.media.baseUrl;
  public previewUrl = '';

  public async uploadFileFunc(evt: any) {
    // TODO: move to utils
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      // eslint-disable-next-line prefer-destructuring
      const data = await backendless.BL.CustomServices.invoke('Media', 'upload', {
        filename: file.name,
        type: 'image',
      });

      const form = new FormData();
      Object.keys(data.fields).forEach((key) => form.append(key, data.fields[key]));

      form.append('Content-Type', file.type);
      form.append('file', file);

      await axios.post(data.url, form, {
        headers: { 'Content-Type': file.type },
      });

      this.previewUrl = data.uri;

      this.onAddPhoto();
      // eslint-disable-next-line no-param-reassign
      evt.target.value = '';
    }
  }

  public onRemove() {
    this.onRemovePhoto();
    this.previewUrl = '';
  }
}
