
































































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { IActorBioForm } from '@/modules/actor/resume/models/actor-bio-form.model';
import { IPlainObject } from '@/_shared/models/plain-object.model';

@Component({
  name: 'actor-bio-form',
})
export default class ActorBioForm extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() user: IUser;
  @Prop() loading: boolean;

  @Emit('updateUser') updateUser() {
    return this.form.fields;
  }

  public form!: IActorBioForm;

  public created() {
    this.form = {
      valid: false,
      fields: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        gender: this.user.gender,
        country: this.user.country,
        city: this.user.city,
        suggestCalling: this.user.suggestCalling,
        union: this.user.union,
        eligible: this.user.eligible,
        location: this.user.location,
      },
      rules: {
        firstName: [FormRules.required('First Name')],
        lastName: [FormRules.required('Last Name')],
        gender: [FormRules.required('Gender')],
        country: [FormRules.required('Country')],
        city: [FormRules.required('City')],
        union: [FormRules.required('Union')],
      },
    };
  }

  public getAddressData(data: IPlainObject) {
    console.log(data);
    this.form.fields.city = data.name;
    this.form.fields.location = {
      type: 'Point',
      coordinates: [data.latitude, data.longitude],
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.updateUser();
    }
  }
}
