






































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IActor } from '@/_shared/models/actor.model';
import { APP_CONFIG } from '@/_core/constants/app.config';
import ClPhotoGallery from '@/_shared/components/cl-photo-gallery/cl-photo-gallery.vue';

@Component({ name: 'actor-detail-popup', components: { ClPhotoGallery } })
export default class ActorDetailPopup extends Vue {
  @Prop(Boolean) isOpened!: boolean;
  @Prop() actor!: IActor;

  @Emit('onClose') onClose() {}

  public selectedImage = 0;
  public galleryOpened = false;
  public mediaBase = APP_CONFIG.media.baseUrl;

  public get userPhotos(): string[] {
    return this.actor && this.actor.mediaPhotos.map(photo => photo.uri);
  }

  public get opened(): boolean {
    return this.isOpened;
  }

  public openGallery(index: number) {
    this.selectedImage = index;
    this.galleryOpened = true;
  }

  public hidePhotoSwipe() {
    this.galleryOpened = false;
  }
}
