
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IChatMessage } from '@/modules/director/casting-calls/models/chat-message.model';
import moment from 'moment';
import { APP_CONFIG } from '@/_core/constants/app.config';

@Component({
  name: 'connection-message',
  filters: {
    updatedAt(time: number): string {
      return moment(time).fromNow();
    },
  },
})
export default class ConnectionMessages extends Vue {
  @Prop() messages!: IChatMessage[];
  @Prop() ownerId!: string;

  @Watch('messages') onMessagesChanged() {
    this.onScrollMessages();
  }

  public mediaBase = APP_CONFIG.media.baseUrl;

  public mounted() {
    this.onScrollMessages();
  }

  public onScrollMessages() {
    setTimeout(() => {
      const el = this.$el.querySelector('.wrapper-chat-messages');
      el.scrollTop = el.scrollHeight;
    }, 0);
  }
}
