import { ICastingSearch } from '@/_shared/models/casting-search.model';
import { IActor } from '@/_shared/models/actor.model';

export interface ICastingSearchState {
  search: ICastingSearch;
  actors: IActor[];
  selectedActors: IActor[];
  isLoading: boolean;
  isCreateLoading: boolean;
  isFindLoading: boolean;
  isConversationsLoading: boolean;
}

export const initialState: ICastingSearchState = {
  isCreateLoading: false,
  isLoading: false,
  search: null,
  actors: [],
  selectedActors: [],
  isFindLoading: false,
  isConversationsLoading: false
};
