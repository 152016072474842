import { render, staticRenderFns } from "./location.vue?vue&type=template&id=a8be9830&scoped=true&"
import script from "./location.vue?vue&type=script&lang=ts&"
export * from "./location.vue?vue&type=script&lang=ts&"
import style0 from "./location.vue?vue&type=style&index=0&id=a8be9830&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8be9830",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VForm,VRow})
