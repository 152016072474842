import { ActionTree } from 'vuex';
import { IRootState } from '@/_core/models/root-state.model';
import { IActorConvState } from '@/modules/actor/connections/store/state';
import {
  ActorConvActionsTypes,
  ActorConvMutationTypes,
} from '@/modules/actor/connections/store/types';
import {
  IChatMessage,
  IConversation,
} from '@/modules/director/casting-calls/models/chat-message.model';
import http from '@/_core/services/axios.service';
import backendless from 'backendless';
import { handleApiError } from '@/_shared/utils/error-handler.util';

export const actions: ActionTree<IActorConvState, IRootState> = {
  async [ActorConvActionsTypes.FETCH]({ commit, rootState }) {
    commit(ActorConvMutationTypes.SET_LOADING, true);

    try {
      let conversations: IConversation[] = await http.get<unknown, IConversation[]>(
        '/data/conversations?loadRelations=casting_director%2Cactor%2Cmessages%2Crole&relationsPageSize=100&relationsDepth=10'
      );

      conversations = conversations.map((conv: IConversation) => ({
        ...conv,
        messages: conv.messages.sort((a: IChatMessage, b: IChatMessage) => a.created - b.created),
      }));
      let filtered = conversations.filter(
        (role: IConversation) =>
          role.actor && role.actor.objectId === rootState.auth.user.objectId && role.messages.length
      );
      filtered = filtered.map((item) => ({
        ...item,
        messages: item.messages.filter((msg) => msg.type !== 'note'),
      }));

      commit(ActorConvMutationTypes.SET_CONVERSATIONS, filtered);
      commit(ActorConvMutationTypes.SET_LOADING, false);
    } catch (e) {
      commit(ActorConvMutationTypes.SET_LOADING, false);
      handleApiError(e);
    }
  },
  async [ActorConvActionsTypes.SEND_MESSAGE]({ commit, rootState }, payload) {
    try {
      commit(ActorConvMutationTypes.ADD_MESSAGE, {
        conversationId: payload.convId,
        message: {
          message: payload.message,
          created: Date.now(),
          ownerId: payload.ownerId,
          type: payload.type,
        },
      });

      const message: IChatMessage = await backendless.Data.of('chat_messages').save<IChatMessage>({
        message: payload.message,
        type: payload.type,
      });
      await backendless.Data.of('chat_messages').setRelation(message, 'conversation_id', [
        payload.convId,
      ]);
      await backendless.Data.of('conversations').addRelation(payload.convId, 'messages', [
        message.objectId,
      ]);
      backendless.Data.of('conversations').setRelation(payload.convId, 'actor', [
        rootState.auth.user.objectId,
      ]);
    } catch (e) {
      handleApiError(e);
    }
  },
};
