









































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoginForm from '@/modules/login/components/login-form/login-form.vue';
import { UserRole } from '@/_core/constants/user-role.const';
import { ICreatUser, ILogin } from '@/modules/login/models/login-form.model';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { AuthActionsTypes } from '@/_core/store/auth/types';
import { LoginMode } from '@/modules/login/constants/login-mode.const';
import LogoIcon from '@/assets/icons/logo.svg';
import GoogleIcon from '@/assets/icons/google.svg';
import FacebookIcon from '@/assets/icons/facebook.svg';
import { Social } from '@/_core/constants/social.const';
import ClMailchimp from '@/_shared/components/cl-mailchimp/cl-mailchimp.vue';

@Component({
  components: { LoginForm, LogoIcon, GoogleIcon, FacebookIcon, ClMailchimp },
})
export default class Login extends Vue {
  @APP_NAMESPACES.auth.Getter isLoading!: boolean;
  @APP_NAMESPACES.auth.Getter emailConfirm!: boolean;

  @APP_NAMESPACES.auth.Action(AuthActionsTypes.LOGIN) login!: (data: ILogin) => void;
  @APP_NAMESPACES.auth.Action(AuthActionsTypes.CREATE_USER) createUser!: (data: ICreatUser) => void;
  @APP_NAMESPACES.auth.Action(AuthActionsTypes.SOCIAL_LOGIN) socialLogin!: (payload: {
    data: ICreatUser;
    social: Social;
  }) => void;

  @Watch('emailConfirm')
  onEmailConfirmChanged(val: boolean) {
    this.emailSnack = val;
  }

  get UserRole() {
    return UserRole;
  }

  get LoginMode() {
    return LoginMode;
  }

  public emailSnack = false;
  public selectedRole = UserRole.ACTOR;
  public selectedMode = LoginMode.CREATE;

  public onModeChanged() {
    this.selectedMode =
      this.selectedMode === this.LoginMode.CREATE ? this.LoginMode.LOGIN : this.LoginMode.CREATE;
  }

  public onRoleChanged(role: UserRole) {
    this.selectedRole = role;
  }

  public onHandleLogin(data: ILogin) {
    if (this.selectedMode === this.LoginMode.CREATE) {
      this.createUser({ ...data, finishedInfo: false, role: this.selectedRole });
      return;
    }

    this.login(data);
  }

  public onGoogleLogin() {
    this.socialLogin({
      data: { role: this.selectedRole, finishedInfo: false },
      social: Social.GOOGLE,
    });
  }

  public onFbLogin() {
    this.socialLogin({
      data: { role: this.selectedRole, finishedInfo: false },
      social: Social.FACEBOOK,
    });
  }
}
