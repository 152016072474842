


















import { Component, Vue } from 'vue-property-decorator';
import ClTab from '@/_shared/components/cl-tabs/cl-tab.vue';

@Component({ name: 'cl-tabs' })
export default class ClTabs extends Vue {
  public tabs: ClTab[] = [];
  public activeTab = 0;

  public mounted() {
    this.tabs = this.$children as ClTab[];
    this.onSelectTab(0);
  }

  public onSelectTab(i: number): void {
    this.activeTab = i;
    this.tabs.forEach((tab: ClTab, index: number): void => {
      // eslint-disable-next-line no-param-reassign
      tab.isActiveTab = index === i;
    });
  }
}
