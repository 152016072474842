

























import { Component, Vue } from 'vue-property-decorator';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { ActorConvActionsTypes } from '@/modules/actor/connections/store/types';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';
import { IConversation } from '@/modules/director/casting-calls/models/chat-message.model';
import ConnectionList
  from '@/modules/actor/connections/components/connection-list/connection-list.vue';
import ClEmptyState from '@/_shared/components/cl-empty-state/cl-empty-state.vue';
import analytic from '@/_core/services/analytic.service';

@Component({
  name: 'connections',
  components: { ClSpinner, ConnectionList, ClEmptyState },
})
export default class Connections extends Vue {
  @APP_NAMESPACES.actorConv.Getter('isLoading') isLoading!: boolean;
  @APP_NAMESPACES.actorConv.Getter('roles') roles!: IConversation[];

  @APP_NAMESPACES.actorConv.Action(ActorConvActionsTypes.FETCH) fetchConversations: () => void;

  public mounted() {
    this.fetchConversations();
    analytic.track(`Navigation: Connections`);
  }
}
