





































































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import { IVisualAppearance } from '@/modules/details/models/actor-stepper-form.model';
import ClUpload from '@/_shared/components/cl-upload/cl-upload.vue';
import { APP_CONFIG } from '@/_core/constants/app.config';

@Component({
  name: 'actor-bio-appearance',
  components: { ClUpload },
})
export default class ActorBioAppearance extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;
  @Prop() loading: boolean;

  @Emit('updateUser') updateUser() {
    return {
      ...this.form.fields,
      playing_age_min: this.form.fields.range[0],
      playing_age_max: this.form.fields.range[1],
    };
  }

  public mediaBase = APP_CONFIG.media.baseUrl;
  public form: IVisualAppearance = {
    valid: false,
    fields: {
      hairColor: this.user.hairColor,
      eyeColor: this.user.eyeColor,
      height: this.user.height,
      weight: this.user.weight,
      mainPhoto: this.user.mainPhoto as string,
      range: [this.user.playing_age_min, this.user.playing_age_max]
    },
    rules: {
      hairColor: [FormRules.required('Hair Color')],
      eyeColor: [FormRules.required('Eye color')],
      height: [FormRules.required('Height')],
      weight: [FormRules.required('Weight')],
      mainPhoto: [FormRules.required('Photo')],
      range: [FormRules.required('Range')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.updateUser();
    }
  }
}
