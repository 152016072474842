


























import { Component, Vue } from 'vue-property-decorator';
import CastingAside from '../../components/casting-aside/casting-aside.vue';
import ActorMessage from '../../components/actor-message/actor-message.vue';
import CastingShortlist from '../../components/casting-shortlist/casting-shortlist.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { CastingsActionsTypes, CastingsMutationTypes } from '@/_core/store/casting-calls/types';
import { ICastingRole } from '@/modules/director/casting-calls/models/casting-call.model';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';
import NotFoundIcon from '@/assets/icons/not_found.svg';
import CameraIcon from '@/assets/icons/camera.svg';
import ClEmptyState from '@/_shared/components/cl-empty-state/cl-empty-state.vue';

@Component({
  name: 'casting-calls',
  components: {
    ClSpinner,
    CastingShortlist,
    ActorMessage,
    CastingAside,
    NotFoundIcon,
    CameraIcon,
    ClEmptyState,
  },
})
export default class CastingCallsDiscussions extends Vue {
  @APP_NAMESPACES.castings.Action(CastingsActionsTypes.FETCH) fetchRoles: () => void;

  @APP_NAMESPACES.castings.Getter('roles') roles!: ICastingRole[];
  @APP_NAMESPACES.castings.Getter('isLoading') isLoading!: boolean;

  @APP_NAMESPACES.castings.Mutation(CastingsMutationTypes.RESET) reset: () => void;

  public mounted() {
    this.fetchRoles();
  }

  public destroyed() {
    this.reset();
  }
}
