






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import PersonalData from './components/personal-data/personal-data.vue';
import Location from './components/location/location.vue';
import { IUser } from '@/_core/models/user.model';
import { StepsLabels } from '@/modules/details/constants/actor-stepper-form.const';
import VisualAppearance from '@/modules/details/components/actor-stepper-form/components/visual-appearance/visual-appearance.vue';
import Experience from '@/modules/details/components/actor-stepper-form/components/experience/experience.vue';
import Skills from '@/modules/details/components/actor-stepper-form/components/skills/skills.vue';
import Portfolio from '@/modules/details/components/actor-stepper-form/components/portfolio/portfolio.vue';
import Resume from '@/modules/details/components/actor-stepper-form/components/resume/resume.vue';
import { APP_NAMESPACES } from '@/_core/store/namespaces';
import { DetailsActionsTypes, DetailsMutationTypes } from '@/_core/store/details/types';
import LogoIcon from '@/assets/icons/logo.svg';
import analytic from '@/_core/services/analytic.service';

@Component({
  name: 'actor-stepper-form',
  components: {
    Resume,
    Skills,
    Experience,
    VisualAppearance,
    PersonalData,
    Location,
    Portfolio,
    LogoIcon,
  },
})
export default class ActorStepperForm extends Vue {
  @Prop() readonly user: IUser;

  @APP_NAMESPACES.details.Mutation(DetailsMutationTypes.SET_STEP) setStep: (step: number) => void;
  @APP_NAMESPACES.details.Action(DetailsActionsTypes.SETUP_ACTOR) setupActor: (
    data: Partial<IUser>
  ) => void;

  @APP_NAMESPACES.details.Getter('currentStep') currentStep!: number;
  @APP_NAMESPACES.details.Getter('isLoading') isLoading!: boolean;

  private info: Partial<IUser> = {};

  public get stepLabel() {
    return StepsLabels;
  }

  public onBack() {
    this.setStep(this.currentStep - 1);
    this.trackStepChanges();
  }

  public onStepChanged(data: Partial<IUser>) {
    this.setStep(this.currentStep + 1);
    this.info = { ...this.info, ...data };
    this.trackStepChanges();
  }

  public onFinishProfile() {
    this.setupActor(this.info);
    analytic.track(`SignUp: Finish setup`);
  }

  private trackStepChanges() {
    // @ts-ignore
    analytic.track(`SignUp: Step ${this.currentStep}. ${this.stepLabel[this.currentStep]}`);
  }
}
