import Vuex from 'vuex';
import { authStoreModule } from './auth';
import { detailsStoreModule } from '@/_core/store/details';
import { castingsStoreModule } from '@/_core/store/casting-calls';
import { castingSearchStoreModule } from '@/_core/store/casting-search';
import { actorConversationsStoreModule } from '@/modules/actor/connections/store';

const appStore = new Vuex.Store({
  modules: {
    auth: authStoreModule,
    details: detailsStoreModule,
    castings: castingsStoreModule,
    castingSearch: castingSearchStoreModule,
    actorConv: actorConversationsStoreModule,
  },
});

export default appStore;
