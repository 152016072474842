






import { Component, Prop, Vue } from 'vue-property-decorator';
import AppLoader from '@/assets/icons/app-loader.svg';

@Component({ name: 'cl-spinner', components: { AppLoader } })
export default class ClSpinner extends Vue {
  @Prop(Boolean) readonly absolute!: boolean;
}
