


















































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { IExperienceForm } from '@/modules/details/models/actor-stepper-form.model';
import { ROLE_INITIAL } from '@/modules/details/constants/experience-step.const';

@Component({ name: 'experience', components: { StepperFooter } })
export default class Experience extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return this.form.fields;
  }

  @Emit('onBack') onBack() {}

  public form: IExperienceForm = {
    valid: false,
    fields: {
      experience: [{ ...ROLE_INITIAL }],
    },
    rules: {
      type: [FormRules.required('Type')],
      name: [FormRules.required('Name')],
      year: [FormRules.required('Year')],
      role: [FormRules.required('Role')],
      studio: [FormRules.required('Studio')],
      director: [FormRules.required('Director')],
    },
  };

  public onAddRole() {
    this.form = {
      ...this.form,
      fields: {
        ...this.form.fields,
        experience: [...this.form.fields.experience, { ...ROLE_INITIAL }],
      },
    };
  }

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onStepChanged();
    }
  }

  public onDelete(index: number) {
    this.form.fields.experience = this.form.fields.experience.filter((_, i) => index !== i);
  }
}
