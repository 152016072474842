




import { Component, Vue } from 'vue-property-decorator';
import ClSpinner from '@/_shared/components/cl-spinner/cl-spinner.vue';

@Component({
  name: 'casting-calls',
  components: { ClSpinner },
})
export default class CastingCalls extends Vue {}
